import { mkNode, scrollRangeIntoView, removeNode, yieldMacroTask, wait } from '../utils';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, AnswerResources, Layout } from '../question-base';
import { Lightbox } from 'lightbox';
import { configSafePress, configSafeTextBox } from 'exam-accessibility';

type Option = {
    value: string;
    backendId?: string;
} | null;

function isOption(opt: unknown): opt is {value: string, backendId?: string} {
    if (typeof opt === 'object' && opt != null) {
        const obj = opt as {[opt:string]:unknown};
        return typeof obj.value === 'string' && (
            typeof obj.backendId === 'undefined' ||
            typeof obj.backendId === 'string'
        );
    }
    return false;
}

/** Dropdown question textarea UI */
export class QuestionDropdown extends QuestionBase implements Question {
    private answerItem: HTMLDivElement;
    //private answerLabel: HTMLDivElement;
    private select: HTMLDivElement;
    public readonly selected: HTMLButtonElement;
    public readonly options: HTMLDivElement;

    private answer: Option;
    private updateVisibility: () => void;
    private handled: boolean;
    private isOpen: boolean;
    private index: number;

    public readonly visibilityExpression?: Expr;

    /** Construct Dropdown Question UI */
    public constructor(
        updateVisibility: () => void,
        context: QuestionContext,
        qno: number,
        ano: number, 
        backendQid: number,
        backendAid: number,
        showNumber: boolean,
        label: string,
        frag: DocumentFragment,
        options: string[],
        optionDetails: PractiqueNet.ExamJson.Definitions.AnswerOptionDetails[],
        lightbox: Lightbox,
        indent?: number,
        visibilityExpression?: Expr,
        optionOrder?: number[],
        notes?: string,
        resources?: AnswerResources
    ) {
        super(context, frag, qno, ano, backendQid, backendAid, showNumber, true, label, lightbox, notes, resources, undefined, showNumber ? Layout.CompactFloat : Layout.Default);
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`; 
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        //this.answerLabel = mkNode('div', {className: 'answer-label', parent: this.answerItem});
        this.select = mkNode('div', {className: 'answer-dropdown ' + configSafeTextBox, parent: this.answerItem, attrib: {tabindex: '0', 'aria-disabled': 'true'}});
        this.selected = mkNode('button', {className: 'answer-dropdown-selected config-background-hover', parent: this.select, attrib: {'aria-expanded': 'false'}});
        this.options = mkNode('div', {className: 'answer-dropdown-options', parent: this.select, hidden: true});
        const elem = mkNode('button', {className: 'answer-dropdown-item ' + configSafePress, parent: this.options});
        elem.innerHTML = '<div class="option-name">Not Answered</div>';
        for (let i = 0; i < options.length; ++i) {
            const idx = (optionOrder) ? optionOrder[i] : i;
            const elem = mkNode('button', {className: 'answer-dropdown-item ' + configSafePress, parent: this.options});
            elem.innerHTML = `<div class="option-name">${options[idx]}</div>${(optionDetails[i]?.description) ? `<div class="option-details">${optionDetails[i].description}</div>` : ''}`;
            elem.dataset.value = options[idx];
            elem.dataset.backendId = String(optionDetails?.[idx]?.backend_id);
        }
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        //this.answerLabel.appendChild(this.label);
        this.answer = null;
        this.handled = false;
        this.isOpen = false;
        this.index = -1;
        this.selected.innerHTML = '<div>&nbsp;</div>';
        //frag.appendChild(this.answerItem);
    }

    public updateDisable(): void {
        super.updateDisable();
        const disabled = this.isDisabled();
        this.select.setAttribute('aria-disabled', String(disabled));
        for (const option of this.options.children) {
            if (option instanceof HTMLButtonElement) {
                option.disabled = disabled;
            }
        }
    }

    /** Load any stored answer */
    public async loadAnswer(): Promise<void> {
        try {
            const response = await this.context.loadAnswer(this.qno, this.ano);
            if (response && isOption(response.answer)) {
                this.answer= response.answer;
                this.selected.innerHTML = '<div>' + this.answer.value+ '</div>';
            } else {
                this.selected.innerHTML = '<div>Not Answered</div>';
            }    const index = this.index;
            if (this.index < 0) {
                this.index = 0;
            } else if (this.index + 1 < this.options.children.length) {
                ++this.index;
            }
            if (this.index != index) {
                if (this.isOpen) {
                    if (index >= 0) {
                        this.options.children[index].setAttribute('aria-pressed', 'false');
                    }
                    this.options.children[this.index].setAttribute('aria-pressed', 'true');
                } /*else {
                    this.value = this.options.children[this.index].innerHTML;
                    this.selected.innerHTML = this.value;
                }*/
            }
            this.updateVisibility();
        } catch(e) {
            console.error(e.toString());
        }
    }

    public loadingComplete(): void {
        super.loadingComplete();
        this.options.addEventListener('mousedown', this.preventCloseHandler);
        this.options.addEventListener('mouseup', this.mouseupHandler);
        this.selected.addEventListener('mousedown', this.mousedownHandler);
        this.select.addEventListener('keydown', this.keyHandler);
        this.context.fullscreenParent.addEventListener('mousedown', this.closeHandler);
    }

    /** Get the answer value */
    public getValue(): string {
        return this.answer?.value ?? '';
    }

    /** Set whether this question is visible or hidden */
    public setVisible(vis: boolean): void {
        this.answerItem.style.display = vis ? 'block' : 'none';
        this.context.setVisible(this.qno, this.ano, vis);
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        this.context.fullscreenParent.removeEventListener('mousedown', this.closeHandler);
        this.select.removeEventListener('keydown', this.keyHandler);
        this.selected.removeEventListener('mousedown', this.mousedownHandler);
        this.options.removeEventListener('mouseup', this.mouseupHandler);
        this.options.removeEventListener('mousedown', this.preventCloseHandler);
        //removeChildren(this.answerLabel);
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem);
    }

    private updateSelection(): void {
        this.index = -1;
        let i = 0;
        let child = this.options.firstElementChild;
        while (child) {
            if (child instanceof HTMLElement && child.dataset.backendId === this.answer?.backendId) {
                child.setAttribute('aria-pressed', 'true');
                this.index = i;
            } else {
                child.setAttribute('aria-pressed', 'false');
            }
            ++i;
            child = child.nextElementSibling;
        }
    }

    private async open(): Promise<void> {
        this.updateSelection();
        this.options.hidden = false;
        this.selected.setAttribute('aria-expanded', 'true');
        this.isOpen = true;
        scrollRangeIntoView(this.answerItem);
    }

    private async close(): Promise<void> {
        this.options.hidden = true;
        this.selected.setAttribute('aria-expanded', 'false');
        this.isOpen = false;
    }

    private async selection(t: Element): Promise<void> {
        if (t instanceof HTMLElement) {
            if (!this.isDisabled() && t.parentElement === this.options) {
                const value = t.dataset.value ?? null;
                const backendId = t.dataset.backendId ?? null;
                if (value && backendId) {
                    if (this.answer?.backendId != backendId || this.answer?.value != value) {
                        this.answer = {value, backendId};
                        this.selected.innerHTML = '<div>' + value + '</div>';
                        try {
                            await this.context.saveAnswer(this.qno, this.ano, Date.now() - this.startTime, {value, backendId}, null);
                        } catch(e) {
                            console.error(e.toSting());
                        }
                        this.updateVisibility();
                    }
                } else if (this.answer !== null) {
                    this.answer = null;
                    this.selected.innerHTML = '<div>Not Answered</div>';
                    try {
                        await this.context.saveAnswer(this.qno, this.ano, Date.now() - this.startTime, null, null);
                    } catch(e) {
                        console.error(e.toSting());
                    }
                } 
            }
            this.close();
        }
    }

    private preventCloseHandler = (): void => {
        this.handled = true;
    }

    private closeHandler = (): void => {
        if (this.handled) {
            this.handled = false;
        } else {
            this.close();
        }
    }

    private keyHandler = async (e: KeyboardEvent): Promise<void> => {
        if (e.target instanceof HTMLElement) {
            switch(e.key) {
                case 'Tab': {
                    if (this.isOpen) {
                        this.close();
                    }
                    break;
                }
                case 'Enter':
                    if (this.isOpen) {
                        this.selection(this.options.children[this.index]);
                    } else {
                        this.open();
                    }
                    break;
                case 'Up':
                case 'ArrowUp':
                    if (!this.isDisabled()) {
                        const index = this.index;
                        if (this.index < 0) {
                            this.index = this.options.children.length - 1;
                        } else if (this.index > 0) {
                            --this.index;
                        }
                        if (this.index != index) {
                            if (this.isOpen) {
                                if (index >= 0) {
                                    this.options.children[index].setAttribute('aria-pressed', 'false');
                                }
                                this.options.children[this.index].setAttribute('aria-pressed', 'true');
                            } /*else {
                                this.value = this.options.children[this.index].innerHTML;
                                this.selected.innerHTML = this.value;
                            }*/
                        }
                    }
                    break;
                case 'Down':
                case 'ArrowDown':
                    if (!this.isDisabled()) {
                        const index = this.index;
                        if (this.index < 0) {
                            this.index = 0;
                        } else if (this.index + 1 < this.options.children.length) {
                            ++this.index;
                        }
                        if (this.index != index) {
                            if (this.isOpen) {
                                if (index >= 0) {
                                    this.options.children[index].setAttribute('aria-pressed', 'false');
                                }
                                this.options.children[this.index].setAttribute('aria-pressed', 'true');
                            } /*else {
                                this.value = this.options.children[this.index].innerHTML;
                                this.selected.innerHTML = this.value;
                            }*/
                        }
                    }
                    break;
            }
        }   
    }

    private startX?: number;
    private startY?: number;

    private mousedownHandler = async (e: MouseEvent): Promise<void> => {
        this.handled = true;
        if (e.target instanceof HTMLElement) {
            if (this.isOpen) {
                this.close();
            } else {
                this.startX = e.clientX;
                this.startY = e.clientY;
                this.open();
            }
        }
    }

    private mouseupHandler = async (e: MouseEvent): Promise<void> => {
        if (e.target instanceof Node) {
            if (e.clientY != this.startY || e.clientX != this.startX) {
                for (const child of this.options.children) {
                    if (child.contains(e.target)) {
                        return this.selection(child);
                    }
                }
            }
            this.startX = undefined;
            this.startY = undefined;
        }
    }
}

registerAnswerType({
    name: 'Dropdown',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer, n: number): boolean => {
        return (answer.type.toLowerCase() === 'sba' && n > 1) || answer.type.toLowerCase() === 'dropdown';
    },
    makeAnswer: (
        pos: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.AnswerDiscrete,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox
    ): Question => {
        return new QuestionDropdown(
            updateVisibility,
            context,
            pos,
            ano,
            question.manifest.backend_id,
            answer.backend_id,
            question.manifest.answers.length > 1,
            answer.label,
            frag,
            answer.options,
            answer.optionDetails ?? [],
            lightbox,
            answer.indent,
            answer.visible,
            answer.candidateToOptionOrder?.[context.candidateCid],
            answer.notes,
            question.answersResources[ano],
        );
    }
});
