import { ControlPanel, NotificationArea } from 'question-base';
import { dbPut } from 'utils-db';
import { translate } from 'utils-lang';
import { mkNode } from './utils';

interface ScheduledMessage {
    time: number;
    duration: number;
    type: 'message';
    value: string;
}

interface ScheduleReadOnly {
    time: number;
    duration: number;
    type: 'read-only';
}

interface ScheduleReadWrite {
    time: number;
    duration: number;
    type: 'read-write';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isScheduledMessage(x: any): x is ScheduledMessage {
    return x && typeof x === 'object' && 
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'message' &&
        (typeof x.value  === 'string' || typeof x.duration === "undefined");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isReadOnly(x: any): x is ScheduleReadOnly {
    return x && typeof x === 'object' && 
        typeof x.time === 'number' &&
        (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
        typeof x.type === 'string' && x.type === 'read-only';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isReadWrite(x: any): x is ScheduleReadWrite {
    return x && typeof x === 'object' && 
    typeof x.time === 'number' &&
    (typeof x.duration === 'number' || typeof x.duration === "undefined") &&
    typeof x.type === 'string' && x.type === 'read-write';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isSchedule(x: any): x is PractiqueNet.ExamJson.Definitions.Schedule {
    if (x && Array.isArray(x)) {
        for (const item of x) {
            if (!(isScheduledMessage(item) || isReadOnly(item) || isReadWrite(item))) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export class ExamTimer {
    private readonly callback: () => Promise<void>;
    private readonly controlPanel: ControlPanel;
    private readonly notificationArea: NotificationArea;
    private readonly timeText: HTMLSpanElement;
    private readonly timer: HTMLButtonElement;
    private readonly down: boolean;
    private elapsedSeconds = 0;
    private currentTime = 0;
    private duration: number;
    private startSeconds: number;
    private interval?: number;
    private schedule: PractiqueNet.ExamJson.Definitions.Schedule;
    private isReadOnly = false;

    private update(): void {
        const t = this.down ? this.duration - this.currentTime : this.currentTime;
        const n = Math.floor(t) < 0;
        const h = Math.floor(Math.abs(t) / 3600);
        const m = Math.floor(Math.abs(t) / 60) - h * 60;
        const s = Math.floor(Math.abs(t)) - h * 3600 - m * 60;
        this.timeText.textContent = `${n ? '-':''}${h}:${('0' + m).slice(-2)}:${('0' + s).slice(-2)}`;

        let readOnly = false;
        const messages: string[] = []
        for (const item of this.schedule) {
            if (this.inRange(item.time, item.duration ?? 60)) {
                if (isScheduledMessage(item)) {
                    if (item.value) {
                        messages.push(item.value);
                    }
                } else if (isReadOnly(item)) {
                    readOnly = true;
                } else if (isReadWrite(item)) {
                    // readOnly = false;
                } else {
                    console.error('UNSUPPORTED SCHEDULE ITEM', item);
                }
            }
        }

        if (messages.length > 0) {
            this.notificationArea.show(messages.join('<br>'));
        } else {
            this.notificationArea.hide();
        }
        if (readOnly !== this.isReadOnly) {
            this.notificationArea.setReadOnly(readOnly);
            this.isReadOnly = readOnly;
        }
    } 

    private inRange(time: number, duration: number): boolean {
        const t = (time < 0) ? this.currentTime - this.duration - time : time + duration - this.currentTime;
        return 0 <= t && t < duration;
    }

    private readonly handleInterval = () => {
        this.currentTime = performance.now() / 1000 - this.startSeconds + this.elapsedSeconds;
        if (this.currentTime >= this.duration) {
            this.currentTime = this.duration;
            this.update();
            this.callback();
        } else {
            this.update();
        }
    }

    private getDuration(): number {
        let duration = 0;
        for (const item of this.schedule) {
            if (isReadOnly(item) || isReadWrite(item)) {
                duration += item.duration;
            }
        }
        return duration;
    }

    constructor(controlPanel: ControlPanel, notificationArea: NotificationArea, timing: PractiqueNet.ExamJson.Definitions.Timing, schedule: PractiqueNet.ExamJson.Definitions.Schedule, callback: () => Promise<void>) {
        this.controlPanel = controlPanel;
        this.notificationArea = notificationArea;
        this.callback = callback;
        this.timeText = mkNode('span', {className: 'app-text'});
        this.timer = mkNode('button', {
            className: 'app-button',
            attrib: {disabled: 'true'},
            children: [
                this.timeText,
                mkNode('span', {className: 'app-button-text', children: [
                    mkNode('text', {text: (timing?.counter === 'down') ? translate('TIMER_REMAINING') : translate('TIMER_ELAPSED')}),
                ]}),
            ]
        });
        this.down = timing.counter === 'down';
        this.startSeconds = performance.now() / 1000;
        this.schedule = schedule;
        this.duration = this.getDuration();
        this.update();
        this.controlPanel.add(this.timer);
    }

    public getElapsed(): number {
        return this.currentTime;
    }

    public setElapsed(seconds: number): void {
        this.elapsedSeconds = seconds;
        this.startSeconds = performance.now() / 1000;
        this.currentTime = this.elapsedSeconds;
        this.update();
    }

    public async setSchedule(schedule: PractiqueNet.ExamJson.Definitions.Schedule): Promise<void> {
        this.schedule = schedule;
        this.duration = this.getDuration();
        this.update();
        await dbPut('users', 'schedule', this.schedule);
    }

    public start(): void {
        if (!this.interval) {
            this.startSeconds = performance.now() / 1000;
            this.interval = window.setInterval(this.handleInterval, 1000);
            this.currentTime = this.elapsedSeconds;
            this.update();
        }
    }

    public stop(): void {
        if (this.interval) {
            window.clearInterval(this.interval);
            this.interval = undefined;

            this.elapsedSeconds += performance.now() / 1000 - this.startSeconds;
            this.currentTime = this.elapsedSeconds;
            this.update();
        }
    }

    public async destroy(): Promise<void> {
        this.controlPanel.remove(this.timer);
    }
}