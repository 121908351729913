import {Progress} from './utils-progress';
import {translate} from 'utils-lang';

let admin = false;
let loginId = "";
let loginPwd = "";
let asCandidateId = "";

export function setCredentials(args: {loginId: string; loginPwd: string; asCandidateId: string}): void {
    admin = false;
    loginId = args.loginId;
    loginPwd = args.loginPwd;
    asCandidateId = '';
}

export function setAsCandidate(c: string): void {
    admin = true;
    asCandidateId = c;
}

export function clearCredentials(): void {
    admin = false;
    loginId = "";
    loginPwd = "";
    asCandidateId = "";
}

export function urlWithCredentials(url: string): string {
    if (loginId && loginPwd) {
        return url +
            '?cid=' + encodeURIComponent(loginId) +
            '&pin=' + encodeURIComponent(loginPwd) +
            ((admin && asCandidateId) ? ('&as=' + encodeURIComponent(asCandidateId)) : '');
    } else {
        return url;
    }
}

/*
const config = new Configuration({
    basePath: '',
    apiKey: (key): string => {
        switch (key) {
            case 'p4bCid': return loginId;
            case 'p4bPin': return loginPwd;
            case 'p4bAs': return asCandidateId;
            default: return '';
        }
    },
});

export const examsApi = new ExamsApi(config); 
*/

export class HttpError extends Error {
    constructor(public readonly status: number, message: string) {
        super(message);
        this.name = 'HttpError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

const postMap: Map<string, XMLHttpRequest> = new Map();

function createXhr(url: string): XMLHttpRequest {
    let xhr = postMap.get(url);
    if (xhr != undefined) {
        //if (xhr.readyState < 4) {
            //console.log('POST ABORTED:', url);
            xhr.abort();
        //}
    } else {
        xhr = new XMLHttpRequest();
        postMap.set(url, xhr);
    }
    return xhr;
}

export function examCleanup(): void {
    postMap.clear();
}

export async function postForm(obj: string, url: string): Promise<void> {
    await post('application/x-www-form-urlencoded', obj, url);
}

export async function postJson<T>(obj: T, url: string): Promise<void> {
    await post('application/json', JSON.stringify(obj), url);
}

export function post(mime: string, obj: string, url: string): Promise<void> {
    const xhr = createXhr(url);
    try {
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', mime);
        xhr.send(obj); 
    } catch(err) {
        postMap.delete(url);
        throw err;
    }
    return new Promise<void>((succ, fail): void => {
        xhr.onerror = (): void => {
            const oldXhr = postMap.get(url);
            if (xhr === oldXhr) {
                postMap.delete(url);
            }
            fail(new Error(translate('ERROR_NETWORK')));
        };
        xhr.ontimeout = (): void => {
            const oldXhr = postMap.get(url);
            if (xhr === oldXhr) {
                postMap.delete(url);
            }
            fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
        };
        xhr.onload = (): void => {
            const oldXhr = postMap.get(url);
            if (xhr === oldXhr) {
                postMap.delete(url);
            }
            if (xhr.status === 200) {
                //console.log('STATUS 200');
                succ();
            } else {
                //console.log('STATUS', xhr.status, xhr.statusText);
                fail(new HttpError(xhr.status, xhr.statusText));
            }
        }
   });
}

export async function requestJson<T>(url: string, obj: T): Promise<unknown> {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'text';
    xhr.send(JSON.stringify(obj));
    return new Promise((succ, fail): void => {
        xhr.onerror = (): void => {
            fail(new Error(translate('ERROR_NETWORK')));
        };
        xhr.ontimeout = (): void => {
            fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
        };
        xhr.onload = (): void => {
            if (xhr.status === 200) {
                if (xhr.responseText) {
                    succ(JSON.parse(xhr.responseText));
                } else {
                    succ(null);
                }
            } else {
                fail(new HttpError(xhr.status, xhr.statusText));
            }
        };
    });
}

export function getHead(url: string): Promise<string> {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, true);
    xhr.send(null);
    return new Promise((succ, fail): void => {
        xhr.onerror = (): void => {
            fail(new Error(translate('ERROR_NETWORK')));
        };
        xhr.ontimeout = (): void => {
            fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
        };
        xhr.onload = (): void => {
            if (xhr.status === 200) {
                succ(xhr.getResponseHeader('content-length') || '0');
            } else {
                fail(new HttpError(xhr.status, xhr.statusText));
            }
        };
    });
}

export function getText(url: string): Promise<string> {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'text';
    xhr.send(null);
    return new Promise((succ, fail): void => {
        xhr.onerror = (): void => {
            fail(new Error(translate('ERROR_NETWORK')));
        };
        xhr.ontimeout = (): void => {
            fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
        };
        xhr.onload = (): void => {
            if (xhr.status === 200) {
                succ(xhr.responseText);
            } else {
                fail(new HttpError(xhr.status, xhr.statusText));
            }
        };
    });
}

export async function getJson(url: string): Promise<unknown> {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'text';
    xhr.send(null);
    return new Promise((succ, fail): void => {
        xhr.onerror = (): void => {
            fail(new Error(translate('ERROR_NETWORK')));
        };
        xhr.ontimeout = (): void => {
            fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
        };
        xhr.onload = (): void => {
            if (xhr.status === 200) {
                succ(JSON.parse(xhr.responseText));
            } else {
                fail(new HttpError(xhr.status, xhr.statusText));
            }
        };
    });
}

export function httpDelete(url: string): Promise<void> {
    const xhr = new XMLHttpRequest();
    xhr.open('DELETE', url, true);
    xhr.send(null);
    return new Promise((succ, fail): void => {
        xhr.onerror = (): void => {
            fail(new Error(translate('ERROR_NETWORK')));
        };
        xhr.ontimeout = (): void => {
            fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
        };
        xhr.onload = (): void => {
            if (xhr.status === 200) {
                succ();
            } else {
                fail(new HttpError(xhr.status, xhr.statusText));
            }
        };
    });
}

export interface ProgressUi {
    ui: {
        progressBox: HTMLDivElement;
        titleBox: HTMLDivElement;
        subtextBox: HTMLDivElement;
        title: Text;
        subtext: Text;
        spinner: HTMLDivElement;
        textBox: HTMLDivElement;
        text: Text;
    };
    currentSize?: number;
    totalSize?: number;
}

export async function rangeSupported(url: string): Promise<{ranged:boolean,size:number}> {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, true);
    xhr.setRequestHeader('Range', 'bytes=0-1');
    xhr.responseType = 'arraybuffer';
    xhr.send(null);
    return new Promise((succ, fail): void => {
        xhr.onerror = (): void => {
            fail(new Error(translate('ERROR_NETWORK')));
        };
        xhr.ontimeout = (): void => {
            fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
        };
        xhr.onload = (): void => {
            if (xhr.status === 206) {
                console.log('RANGE', xhr.getResponseHeader('content-range'));
                console.log('MATCH', xhr.getResponseHeader('content-range')?.match(/\/([0-9]*)$/));
                const ranged = true;
                const size = parseInt(xhr.getResponseHeader('content-range')?.match(/\/([0-9]*)$/)?.[1] || '0');
                succ({ranged, size});
            } else if (xhr.status === 200) {
                const ranged = false;
                const size = parseInt(xhr.getResponseHeader('content-length') || '0');
                succ({ranged, size});
            } else {
                fail(new HttpError(xhr.status, xhr.statusText));
            }
        }
    });
}

export function getArrayBuffer(url: string, progress: Progress, start?: number, end?: number): Promise<ArrayBuffer> {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    if (start || end) {
        console.debug('range', start, '-', end);
        xhr.setRequestHeader('Range', 'bytes=' + start + '-' + end);
    }
    xhr.responseType = 'arraybuffer';
    xhr.send(null);
    return new Promise((succ, fail): void => {
        xhr.onerror = (): void => {
            fail(new Error(translate('ERROR_NETWORK')));
        };
        xhr.ontimeout = (): void => {
            fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
        };
        xhr.onprogress = async (e): Promise<void> => {
            if (e.loaded != null) {
                await progress.setProgress(e.loaded);
            }
        };
        xhr.onload = (): void => {
            if (xhr.status === 200 || xhr.status === 206) {
                progress.addOffset(xhr.response.byteLength);
                console.debug('downloaded exam chunk, start: ', start, ' end: ', start + xhr.response.byteLength);
                succ(xhr.response);
            } else {
                fail(new HttpError(xhr.status, xhr.statusText));
            }
        };
    });
}
