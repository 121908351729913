'use strict';
var equal = require('ajv/lib/compile/equal');
var validate = (function() {
  var refVal = [];
  var refVal1 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          if (data.answer_aes_key === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'answer_aes_key'
              },
              message: 'should have required property \'answer_aes_key\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data.answer_aes_key !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.answer_aes_key',
                schemaPath: '#/properties/answer_aes_key/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.randomize === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.randomize !== "boolean") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.randomize',
                  schemaPath: '#/properties/randomize/type',
                  params: {
                    type: 'boolean'
                  },
                  message: 'should be boolean'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.demo === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.demo !== "boolean") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.demo',
                    schemaPath: '#/properties/demo/type',
                    params: {
                      type: 'boolean'
                    },
                    message: 'should be boolean'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.show_question_title === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.show_question_title !== "boolean") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.show_question_title',
                      schemaPath: '#/properties/show_question_title/type',
                      params: {
                        type: 'boolean'
                      },
                      message: 'should be boolean'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.enableCopyPaste === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.enableCopyPaste !== "boolean") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.enableCopyPaste',
                        schemaPath: '#/properties/enableCopyPaste/type',
                        params: {
                          type: 'boolean'
                        },
                        message: 'should be boolean'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.disableResourceLocking === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.disableResourceLocking !== "boolean") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.disableResourceLocking',
                          schemaPath: '#/properties/disableResourceLocking/type',
                          params: {
                            type: 'boolean'
                          },
                          message: 'should be boolean'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.disableCalculator === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.disableCalculator !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.disableCalculator',
                            schemaPath: '#/properties/disableCalculator/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.disableAnswerElimination === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.disableAnswerElimination !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.disableAnswerElimination',
                              schemaPath: '#/properties/disableAnswerElimination/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.disableDicomGrid === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.disableDicomGrid !== "boolean") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.disableDicomGrid',
                                schemaPath: '#/properties/disableDicomGrid/type',
                                params: {
                                  type: 'boolean'
                                },
                                message: 'should be boolean'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.hideEmptyItems === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.hideEmptyItems !== "boolean") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.hideEmptyItems',
                                  schemaPath: '#/properties/hideEmptyItems/type',
                                  params: {
                                    type: 'boolean'
                                  },
                                  message: 'should be boolean'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.timing === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (!refVal2(data.timing, (dataPath || '') + '.timing', data, 'timing', rootData)) {
                                  if (vErrors === null) vErrors = refVal2.errors;
                                  else vErrors = vErrors.concat(refVal2.errors);
                                  errors = vErrors.length;
                                }
                                var valid1 = errors === errs_1;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal1.schema = {
    "type": "object",
    "required": ["answer_aes_key"],
    "properties": {
      "answer_aes_key": {
        "type": "string"
      },
      "randomize": {
        "type": "boolean"
      },
      "demo": {
        "type": "boolean"
      },
      "show_question_title": {
        "type": "boolean"
      },
      "enableCopyPaste": {
        "type": "boolean"
      },
      "disableResourceLocking": {
        "type": "boolean"
      },
      "disableCalculator": {
        "type": "boolean"
      },
      "disableAnswerElimination": {
        "type": "boolean"
      },
      "disableDicomGrid": {
        "type": "boolean"
      },
      "hideEmptyItems": {
        "type": "boolean"
      },
      "timing": {
        "$ref": "#/definitions/timing"
      }
    }
  };
  refVal1.errors = null;
  refVal[1] = refVal1;
  var refVal2 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        var missing0;
        if (((data.duration === undefined) && (missing0 = '.duration'))) {
          validate.errors = [{
            keyword: 'required',
            dataPath: (dataPath || '') + "",
            schemaPath: '#/required',
            params: {
              missingProperty: '' + missing0 + ''
            },
            message: 'should have required property \'' + missing0 + '\''
          }];
          return false;
        } else {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.counter;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'counter'
              },
              message: 'should have required property \'counter\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.counter',
                schemaPath: '#/properties/counter/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var schema1 = validate.schema.properties.counter.enum;
            var valid1;
            valid1 = false;
            for (var i1 = 0; i1 < schema1.length; i1++)
              if (equal(data1, schema1[i1])) {
                valid1 = true;
                break;
              } if (!valid1) {
              validate.errors = [{
                keyword: 'enum',
                dataPath: (dataPath || '') + '.counter',
                schemaPath: '#/properties/counter/enum',
                params: {
                  allowedValues: schema1
                },
                message: 'should be equal to one of the allowed values'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.schedule === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'schedule'
                },
                message: 'should have required property \'schedule\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (!refVal3(data.schedule, (dataPath || '') + '.schedule', data, 'schedule', rootData)) {
                if (vErrors === null) vErrors = refVal3.errors;
                else vErrors = vErrors.concat(refVal3.errors);
                errors = vErrors.length;
              }
              var valid1 = errors === errs_1;
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal2.schema = {
    "type": "object",
    "required": ["counter", "duration", "schedule"],
    "properties": {
      "counter": {
        "type": "string",
        "enum": ["up", "down", "hide"]
      },
      "schedule": {
        "$ref": "#/definitions/schedule"
      }
    }
  };
  refVal2.errors = null;
  refVal[2] = refVal2;
  var refVal3 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (Array.isArray(data)) {
        var errs__0 = errors;
        var valid0;
        for (var i0 = 0; i0 < data.length; i0++) {
          var data1 = data[i0];
          var errs_1 = errors;
          var errs__1 = errors,
            prevValid1 = false,
            valid1 = false,
            passingSchemas1 = null;
          var errs_2 = errors;
          var errs_3 = errors;
          if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
            if (true) {
              var errs__3 = errors;
              var valid4 = true;
              if (data1.time === undefined) {
                valid4 = false;
                var err = {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '[' + i0 + ']',
                  schemaPath: '#/definitions/scheduleMessage/required',
                  params: {
                    missingProperty: 'time'
                  },
                  message: 'should have required property \'time\''
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
                var errs_4 = errors;
                if (typeof data1.time !== "number") {
                  var err = {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '[' + i0 + '].time',
                    schemaPath: '#/definitions/scheduleMessage/properties/time/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                }
                var valid4 = errors === errs_4;
              }
              if (valid4) {
                if (data1.duration === undefined) {
                  valid4 = true;
                } else {
                  var errs_4 = errors;
                  if (typeof data1.duration !== "number") {
                    var err = {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '[' + i0 + '].duration',
                      schemaPath: '#/definitions/scheduleMessage/properties/duration/type',
                      params: {
                        type: 'number'
                      },
                      message: 'should be number'
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                  }
                  var valid4 = errors === errs_4;
                }
                if (valid4) {
                  var data2 = data1.type;
                  if (data2 === undefined) {
                    valid4 = false;
                    var err = {
                      keyword: 'required',
                      dataPath: (dataPath || '') + '[' + i0 + ']',
                      schemaPath: '#/definitions/scheduleMessage/required',
                      params: {
                        missingProperty: 'type'
                      },
                      message: 'should have required property \'type\''
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                  } else {
                    var errs_4 = errors;
                    if (typeof data2 !== "string") {
                      var err = {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '[' + i0 + '].type',
                        schemaPath: '#/definitions/scheduleMessage/properties/type/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    var schema4 = refVal4.properties.type.enum;
                    var valid4;
                    valid4 = false;
                    for (var i4 = 0; i4 < schema4.length; i4++)
                      if (equal(data2, schema4[i4])) {
                        valid4 = true;
                        break;
                      } if (!valid4) {
                      var err = {
                        keyword: 'enum',
                        dataPath: (dataPath || '') + '[' + i0 + '].type',
                        schemaPath: '#/definitions/scheduleMessage/properties/type/enum',
                        params: {
                          allowedValues: schema4
                        },
                        message: 'should be equal to one of the allowed values'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    var valid4 = errors === errs_4;
                  }
                  if (valid4) {
                    if (data1.value === undefined) {
                      valid4 = true;
                    } else {
                      var errs_4 = errors;
                      if (typeof data1.value !== "string") {
                        var err = {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[' + i0 + '].value',
                          schemaPath: '#/definitions/scheduleMessage/properties/value/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      }
                      var valid4 = errors === errs_4;
                    }
                  }
                }
              }
            }
          } else {
            var err = {
              keyword: 'type',
              dataPath: (dataPath || '') + '[' + i0 + ']',
              schemaPath: '#/definitions/scheduleMessage/type',
              params: {
                type: 'object'
              },
              message: 'should be object'
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          }
          var valid3 = errors === errs_3;
          var valid2 = errors === errs_2;
          if (valid2) {
            valid1 = prevValid1 = true;
            passingSchemas1 = 0;
          }
          var errs_2 = errors;
          var errs_3 = errors;
          if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
            if (true) {
              var errs__3 = errors;
              var valid4 = true;
              if (data1.time === undefined) {
                valid4 = false;
                var err = {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '[' + i0 + ']',
                  schemaPath: '#/definitions/scheduleReadOnly/required',
                  params: {
                    missingProperty: 'time'
                  },
                  message: 'should have required property \'time\''
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
                var errs_4 = errors;
                if (typeof data1.time !== "number") {
                  var err = {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '[' + i0 + '].time',
                    schemaPath: '#/definitions/scheduleReadOnly/properties/time/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                }
                var valid4 = errors === errs_4;
              }
              if (valid4) {
                if (data1.duration === undefined) {
                  valid4 = true;
                } else {
                  var errs_4 = errors;
                  if (typeof data1.duration !== "number") {
                    var err = {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '[' + i0 + '].duration',
                      schemaPath: '#/definitions/scheduleReadOnly/properties/duration/type',
                      params: {
                        type: 'number'
                      },
                      message: 'should be number'
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                  }
                  var valid4 = errors === errs_4;
                }
                if (valid4) {
                  var data2 = data1.type;
                  if (data2 === undefined) {
                    valid4 = false;
                    var err = {
                      keyword: 'required',
                      dataPath: (dataPath || '') + '[' + i0 + ']',
                      schemaPath: '#/definitions/scheduleReadOnly/required',
                      params: {
                        missingProperty: 'type'
                      },
                      message: 'should have required property \'type\''
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                  } else {
                    var errs_4 = errors;
                    if (typeof data2 !== "string") {
                      var err = {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '[' + i0 + '].type',
                        schemaPath: '#/definitions/scheduleReadOnly/properties/type/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    var schema4 = refVal5.properties.type.enum;
                    var valid4;
                    valid4 = false;
                    for (var i4 = 0; i4 < schema4.length; i4++)
                      if (equal(data2, schema4[i4])) {
                        valid4 = true;
                        break;
                      } if (!valid4) {
                      var err = {
                        keyword: 'enum',
                        dataPath: (dataPath || '') + '[' + i0 + '].type',
                        schemaPath: '#/definitions/scheduleReadOnly/properties/type/enum',
                        params: {
                          allowedValues: schema4
                        },
                        message: 'should be equal to one of the allowed values'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    var valid4 = errors === errs_4;
                  }
                }
              }
            }
          } else {
            var err = {
              keyword: 'type',
              dataPath: (dataPath || '') + '[' + i0 + ']',
              schemaPath: '#/definitions/scheduleReadOnly/type',
              params: {
                type: 'object'
              },
              message: 'should be object'
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          }
          var valid3 = errors === errs_3;
          var valid2 = errors === errs_2;
          if (valid2 && prevValid1) {
            valid1 = false;
            passingSchemas1 = [passingSchemas1, 1];
          } else {
            if (valid2) {
              valid1 = prevValid1 = true;
              passingSchemas1 = 1;
            }
            var errs_2 = errors;
            var errs_3 = errors;
            if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
              if (true) {
                var errs__3 = errors;
                var valid4 = true;
                if (data1.time === undefined) {
                  valid4 = false;
                  var err = {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '[' + i0 + ']',
                    schemaPath: '#/definitions/scheduleReadWrite/required',
                    params: {
                      missingProperty: 'time'
                    },
                    message: 'should have required property \'time\''
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                } else {
                  var errs_4 = errors;
                  if (typeof data1.time !== "number") {
                    var err = {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '[' + i0 + '].time',
                      schemaPath: '#/definitions/scheduleReadWrite/properties/time/type',
                      params: {
                        type: 'number'
                      },
                      message: 'should be number'
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                  }
                  var valid4 = errors === errs_4;
                }
                if (valid4) {
                  if (data1.duration === undefined) {
                    valid4 = true;
                  } else {
                    var errs_4 = errors;
                    if (typeof data1.duration !== "number") {
                      var err = {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '[' + i0 + '].duration',
                        schemaPath: '#/definitions/scheduleReadWrite/properties/duration/type',
                        params: {
                          type: 'number'
                        },
                        message: 'should be number'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    var valid4 = errors === errs_4;
                  }
                  if (valid4) {
                    var data2 = data1.type;
                    if (data2 === undefined) {
                      valid4 = false;
                      var err = {
                        keyword: 'required',
                        dataPath: (dataPath || '') + '[' + i0 + ']',
                        schemaPath: '#/definitions/scheduleReadWrite/required',
                        params: {
                          missingProperty: 'type'
                        },
                        message: 'should have required property \'type\''
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    } else {
                      var errs_4 = errors;
                      if (typeof data2 !== "string") {
                        var err = {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[' + i0 + '].type',
                          schemaPath: '#/definitions/scheduleReadWrite/properties/type/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      }
                      var schema4 = refVal6.properties.type.enum;
                      var valid4;
                      valid4 = false;
                      for (var i4 = 0; i4 < schema4.length; i4++)
                        if (equal(data2, schema4[i4])) {
                          valid4 = true;
                          break;
                        } if (!valid4) {
                        var err = {
                          keyword: 'enum',
                          dataPath: (dataPath || '') + '[' + i0 + '].type',
                          schemaPath: '#/definitions/scheduleReadWrite/properties/type/enum',
                          params: {
                            allowedValues: schema4
                          },
                          message: 'should be equal to one of the allowed values'
                        };
                        if (vErrors === null) vErrors = [err];
                        else vErrors.push(err);
                        errors++;
                      }
                      var valid4 = errors === errs_4;
                    }
                  }
                }
              }
            } else {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '[' + i0 + ']',
                schemaPath: '#/definitions/scheduleReadWrite/type',
                params: {
                  type: 'object'
                },
                message: 'should be object'
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            var valid3 = errors === errs_3;
            var valid2 = errors === errs_2;
            if (valid2 && prevValid1) {
              valid1 = false;
              passingSchemas1 = [passingSchemas1, 2];
            } else {
              if (valid2) {
                valid1 = prevValid1 = true;
                passingSchemas1 = 2;
              }
            }
          }
          if (!valid1) {
            var err = {
              keyword: 'oneOf',
              dataPath: (dataPath || '') + '[' + i0 + ']',
              schemaPath: '#/items/oneOf',
              params: {
                passingSchemas: passingSchemas1
              },
              message: 'should match exactly one schema in oneOf'
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
            validate.errors = vErrors;
            return false;
          } else {
            errors = errs__1;
            if (vErrors !== null) {
              if (errs__1) vErrors.length = errs__1;
              else vErrors = null;
            }
          }
          var valid1 = errors === errs_1;
          if (!valid1) break;
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'array'
          },
          message: 'should be array'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal3.schema = {
    "type": "array",
    "items": {
      "oneOf": [{
        "$ref": "#/definitions/scheduleMessage"
      }, {
        "$ref": "#/definitions/scheduleReadOnly"
      }, {
        "$ref": "#/definitions/scheduleReadWrite"
      }]
    }
  };
  refVal3.errors = null;
  refVal[3] = refVal3;
  var refVal4 = {
    "type": "object",
    "required": ["time", "type"],
    "properties": {
      "time": {
        "type": "number"
      },
      "duration": {
        "type": "number"
      },
      "type": {
        "type": "string",
        "enum": ["message"]
      },
      "value": {
        "type": "string"
      }
    }
  };
  refVal[4] = refVal4;
  var refVal5 = {
    "type": "object",
    "required": ["time", "type"],
    "properties": {
      "time": {
        "type": "number"
      },
      "duration": {
        "type": "number"
      },
      "type": {
        "type": "string",
        "enum": ["read-only"]
      }
    }
  };
  refVal[5] = refVal5;
  var refVal6 = {
    "type": "object",
    "required": ["time", "type"],
    "properties": {
      "time": {
        "type": "number"
      },
      "duration": {
        "type": "number"
      },
      "type": {
        "type": "string",
        "enum": ["read-write"]
      }
    }
  };
  refVal[6] = refVal6;
  var refVal7 = {
    "type": "object",
    "properties": {
      "given_name": {
        "type": "string"
      },
      "family_name": {
        "type": "string"
      },
      "password_hash": {
        "type": "string"
      },
      "variantIndex": {
        "type": "integer"
      }
    }
  };
  refVal[7] = refVal7;
  var refVal8 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          if (data.title === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'title'
              },
              message: 'should have required property \'title\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data.title !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.title',
                schemaPath: '#/properties/title/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.stem === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'stem'
                },
                message: 'should have required property \'stem\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.stem !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.stem',
                  schemaPath: '#/properties/stem/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.backend_id === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'backend_id'
                  },
                  message: 'should have required property \'backend_id\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data.backend_id !== "number") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.backend_id',
                    schemaPath: '#/properties/backend_id/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.images;
                if (data1 === undefined) {
                  valid1 = false;
                  validate.errors = [{
                    keyword: 'required',
                    dataPath: (dataPath || '') + "",
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'images'
                    },
                    message: 'should have required property \'images\''
                  }];
                  return false;
                } else {
                  var errs_1 = errors;
                  if (Array.isArray(data1)) {
                    var errs__1 = errors;
                    var valid1;
                    for (var i1 = 0; i1 < data1.length; i1++) {
                      var errs_2 = errors;
                      if (!refVal9(data1[i1], (dataPath || '') + '.images[' + i1 + ']', data1, i1, rootData)) {
                        if (vErrors === null) vErrors = refVal9.errors;
                        else vErrors = vErrors.concat(refVal9.errors);
                        errors = vErrors.length;
                      }
                      var valid2 = errors === errs_2;
                      if (!valid2) break;
                    }
                  } else {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.images',
                      schemaPath: '#/properties/images/type',
                      params: {
                        type: 'array'
                      },
                      message: 'should be array'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.answers;
                  if (data1 === undefined) {
                    valid1 = false;
                    validate.errors = [{
                      keyword: 'required',
                      dataPath: (dataPath || '') + "",
                      schemaPath: '#/required',
                      params: {
                        missingProperty: 'answers'
                      },
                      message: 'should have required property \'answers\''
                    }];
                    return false;
                  } else {
                    var errs_1 = errors;
                    if (Array.isArray(data1)) {
                      var errs__1 = errors;
                      var valid1;
                      for (var i1 = 0; i1 < data1.length; i1++) {
                        var errs_2 = errors;
                        if (!refVal13(data1[i1], (dataPath || '') + '.answers[' + i1 + ']', data1, i1, rootData)) {
                          if (vErrors === null) vErrors = refVal13.errors;
                          else vErrors = vErrors.concat(refVal13.errors);
                          errors = vErrors.length;
                        }
                        var valid2 = errors === errs_2;
                        if (!valid2) break;
                      }
                    } else {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.answers',
                        schemaPath: '#/properties/answers/type',
                        params: {
                          type: 'array'
                        },
                        message: 'should be array'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    var data1 = data.answersResources;
                    if (data1 === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (Array.isArray(data1)) {
                        var errs__1 = errors;
                        var valid1;
                        for (var i1 = 0; i1 < data1.length; i1++) {
                          var data2 = data1[i1];
                          var errs_2 = errors;
                          if (Array.isArray(data2)) {
                            var errs__2 = errors;
                            var valid2;
                            for (var i2 = 0; i2 < data2.length; i2++) {
                              var errs_3 = errors;
                              if (!refVal[9](data2[i2], (dataPath || '') + '.answersResources[' + i1 + '][' + i2 + ']', data2, i2, rootData)) {
                                if (vErrors === null) vErrors = refVal[9].errors;
                                else vErrors = vErrors.concat(refVal[9].errors);
                                errors = vErrors.length;
                              }
                              var valid3 = errors === errs_3;
                              if (!valid3) break;
                            }
                          } else {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.answersResources[' + i1 + ']',
                              schemaPath: '#/properties/answersResources/items/type',
                              params: {
                                type: 'array'
                              },
                              message: 'should be array'
                            }];
                            return false;
                          }
                          var valid2 = errors === errs_2;
                          if (!valid2) break;
                        }
                      } else {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.answersResources',
                          schemaPath: '#/properties/answersResources/type',
                          params: {
                            type: 'array'
                          },
                          message: 'should be array'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal8.schema = {
    "type": "object",
    "required": ["title", "stem", "backend_id", "images", "answers"],
    "properties": {
      "title": {
        "type": "string"
      },
      "stem": {
        "type": "string"
      },
      "backend_id": {
        "type": "number"
      },
      "images": {
        "type": "array",
        "items": {
          "$ref": "#/definitions/image"
        }
      },
      "answers": {
        "type": "array",
        "items": {
          "$ref": "#/definitions/answer"
        }
      },
      "answersResources": {
        "type": "array",
        "items": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/image"
          }
        }
      }
    }
  };
  refVal8.errors = null;
  refVal[8] = refVal8;
  var refVal9 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      var errs__0 = errors,
        prevValid0 = false,
        valid0 = false,
        passingSchemas0 = null;
      var errs_1 = errors;
      var errs_2 = errors;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__2 = errors;
          var valid3 = true;
          if (data.image === undefined) {
            valid3 = false;
            var err = {
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/definitions/imageSingle/required',
              params: {
                missingProperty: 'image'
              },
              message: 'should have required property \'image\''
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          } else {
            var errs_3 = errors;
            if (typeof data.image !== "string") {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '.image',
                schemaPath: '#/definitions/imageSingle/properties/image/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            var valid3 = errors === errs_3;
          }
          if (valid3) {
            if (data.caption === undefined) {
              valid3 = true;
            } else {
              var errs_3 = errors;
              if (typeof data.caption !== "string") {
                var err = {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.caption',
                  schemaPath: '#/definitions/imageSingle/properties/caption/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              }
              var valid3 = errors === errs_3;
            }
          }
        }
      } else {
        var err = {
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/definitions/imageSingle/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
      }
      var valid2 = errors === errs_2;
      var valid1 = errors === errs_1;
      if (valid1) {
        valid0 = prevValid0 = true;
        passingSchemas0 = 0;
      }
      var errs_1 = errors;
      var errs_2 = errors;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__2 = errors;
          var valid3 = true;
          var data1 = data.set;
          if (data1 === undefined) {
            valid3 = false;
            var err = {
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/definitions/imageSet/required',
              params: {
                missingProperty: 'set'
              },
              message: 'should have required property \'set\''
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          } else {
            var errs_3 = errors;
            if (Array.isArray(data1)) {
              var errs__3 = errors;
              var valid3;
              for (var i3 = 0; i3 < data1.length; i3++) {
                var errs_4 = errors;
                if (typeof data1[i3] !== "string") {
                  var err = {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.set[' + i3 + ']',
                    schemaPath: '#/definitions/imageSet/properties/set/items/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                }
                var valid4 = errors === errs_4;
                if (!valid4) break;
              }
            } else {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '.set',
                schemaPath: '#/definitions/imageSet/properties/set/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            var valid3 = errors === errs_3;
          }
          if (valid3) {
            if (data.caption === undefined) {
              valid3 = true;
            } else {
              var errs_3 = errors;
              if (typeof data.caption !== "string") {
                var err = {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.caption',
                  schemaPath: '#/definitions/imageSet/properties/caption/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              }
              var valid3 = errors === errs_3;
            }
          }
        }
      } else {
        var err = {
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/definitions/imageSet/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
      }
      var valid2 = errors === errs_2;
      var valid1 = errors === errs_1;
      if (valid1 && prevValid0) {
        valid0 = false;
        passingSchemas0 = [passingSchemas0, 1];
      } else {
        if (valid1) {
          valid0 = prevValid0 = true;
          passingSchemas0 = 1;
        }
        var errs_1 = errors;
        var errs_2 = errors;
        if ((data && typeof data === "object" && !Array.isArray(data))) {
          if (true) {
            var errs__2 = errors;
            var valid3 = true;
            if (data.file === undefined) {
              valid3 = false;
              var err = {
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/definitions/resource/required',
                params: {
                  missingProperty: 'file'
                },
                message: 'should have required property \'file\''
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            } else {
              var errs_3 = errors;
              if (typeof data.file !== "string") {
                var err = {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.file',
                  schemaPath: '#/definitions/resource/properties/file/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              }
              var valid3 = errors === errs_3;
            }
            if (valid3) {
              var data1 = data.type;
              if (data1 === undefined) {
                valid3 = false;
                var err = {
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/definitions/resource/required',
                  params: {
                    missingProperty: 'type'
                  },
                  message: 'should have required property \'type\''
                };
                if (vErrors === null) vErrors = [err];
                else vErrors.push(err);
                errors++;
              } else {
                var errs_3 = errors;
                if (typeof data1 !== "string") {
                  var err = {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.type',
                    schemaPath: '#/definitions/resource/properties/type/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                }
                var schema3 = refVal12.properties.type.enum;
                var valid3;
                valid3 = false;
                for (var i3 = 0; i3 < schema3.length; i3++)
                  if (equal(data1, schema3[i3])) {
                    valid3 = true;
                    break;
                  } if (!valid3) {
                  var err = {
                    keyword: 'enum',
                    dataPath: (dataPath || '') + '.type',
                    schemaPath: '#/definitions/resource/properties/type/enum',
                    params: {
                      allowedValues: schema3
                    },
                    message: 'should be equal to one of the allowed values'
                  };
                  if (vErrors === null) vErrors = [err];
                  else vErrors.push(err);
                  errors++;
                }
                var valid3 = errors === errs_3;
              }
              if (valid3) {
                if (data.caption === undefined) {
                  valid3 = true;
                } else {
                  var errs_3 = errors;
                  if (typeof data.caption !== "string") {
                    var err = {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.caption',
                      schemaPath: '#/definitions/resource/properties/caption/type',
                      params: {
                        type: 'string'
                      },
                      message: 'should be string'
                    };
                    if (vErrors === null) vErrors = [err];
                    else vErrors.push(err);
                    errors++;
                  }
                  var valid3 = errors === errs_3;
                }
                if (valid3) {
                  var data1 = data.mime;
                  if (data1 === undefined) {
                    valid3 = true;
                  } else {
                    var errs_3 = errors;
                    if (typeof data1 !== "string" && data1 !== null) {
                      var err = {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.mime',
                        schemaPath: '#/definitions/resource/properties/mime/type',
                        params: {
                          type: 'string,null'
                        },
                        message: 'should be string,null'
                      };
                      if (vErrors === null) vErrors = [err];
                      else vErrors.push(err);
                      errors++;
                    }
                    var valid3 = errors === errs_3;
                  }
                }
              }
            }
          }
        } else {
          var err = {
            keyword: 'type',
            dataPath: (dataPath || '') + "",
            schemaPath: '#/definitions/resource/type',
            params: {
              type: 'object'
            },
            message: 'should be object'
          };
          if (vErrors === null) vErrors = [err];
          else vErrors.push(err);
          errors++;
        }
        var valid2 = errors === errs_2;
        var valid1 = errors === errs_1;
        if (valid1 && prevValid0) {
          valid0 = false;
          passingSchemas0 = [passingSchemas0, 2];
        } else {
          if (valid1) {
            valid0 = prevValid0 = true;
            passingSchemas0 = 2;
          }
        }
      }
      if (!valid0) {
        var err = {
          keyword: 'oneOf',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/oneOf',
          params: {
            passingSchemas: passingSchemas0
          },
          message: 'should match exactly one schema in oneOf'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
        validate.errors = vErrors;
        return false;
      } else {
        errors = errs__0;
        if (vErrors !== null) {
          if (errs__0) vErrors.length = errs__0;
          else vErrors = null;
        }
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal9.schema = {
    "oneOf": [{
      "$ref": "#/definitions/imageSingle"
    }, {
      "$ref": "#/definitions/imageSet"
    }, {
      "$ref": "#/definitions/resource"
    }]
  };
  refVal9.errors = null;
  refVal[9] = refVal9;
  var refVal10 = {
    "type": "object",
    "required": ["image"],
    "properties": {
      "image": {
        "type": "string"
      },
      "caption": {
        "type": "string"
      }
    }
  };
  refVal[10] = refVal10;
  var refVal11 = {
    "type": "object",
    "required": ["set"],
    "properties": {
      "set": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "caption": {
        "type": "string"
      }
    }
  };
  refVal[11] = refVal11;
  var refVal12 = {
    "type": "object",
    "required": ["file", "type"],
    "properties": {
      "file": {
        "type": "string"
      },
      "type": {
        "type": "string",
        "enum": ["video", "audio", "pdf"]
      },
      "caption": {
        "type": "string"
      },
      "mime": {
        "type": ["string", "null"]
      }
    }
  };
  refVal[12] = refVal12;
  var refVal13 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      var errs__0 = errors,
        prevValid0 = false,
        valid0 = false,
        passingSchemas0 = null;
      var errs_1 = errors;
      if (!refVal14(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
        if (vErrors === null) vErrors = refVal14.errors;
        else vErrors = vErrors.concat(refVal14.errors);
        errors = vErrors.length;
      }
      var valid1 = errors === errs_1;
      if (valid1) {
        valid0 = prevValid0 = true;
        passingSchemas0 = 0;
      }
      var errs_1 = errors;
      if (!refVal28(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
        if (vErrors === null) vErrors = refVal28.errors;
        else vErrors = vErrors.concat(refVal28.errors);
        errors = vErrors.length;
      }
      var valid1 = errors === errs_1;
      if (valid1 && prevValid0) {
        valid0 = false;
        passingSchemas0 = [passingSchemas0, 1];
      } else {
        if (valid1) {
          valid0 = prevValid0 = true;
          passingSchemas0 = 1;
        }
        var errs_1 = errors;
        if (!refVal29(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
          if (vErrors === null) vErrors = refVal29.errors;
          else vErrors = vErrors.concat(refVal29.errors);
          errors = vErrors.length;
        }
        var valid1 = errors === errs_1;
        if (valid1 && prevValid0) {
          valid0 = false;
          passingSchemas0 = [passingSchemas0, 2];
        } else {
          if (valid1) {
            valid0 = prevValid0 = true;
            passingSchemas0 = 2;
          }
          var errs_1 = errors;
          if (!refVal30(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
            if (vErrors === null) vErrors = refVal30.errors;
            else vErrors = vErrors.concat(refVal30.errors);
            errors = vErrors.length;
          }
          var valid1 = errors === errs_1;
          if (valid1 && prevValid0) {
            valid0 = false;
            passingSchemas0 = [passingSchemas0, 3];
          } else {
            if (valid1) {
              valid0 = prevValid0 = true;
              passingSchemas0 = 3;
            }
            var errs_1 = errors;
            if (!refVal33(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
              if (vErrors === null) vErrors = refVal33.errors;
              else vErrors = vErrors.concat(refVal33.errors);
              errors = vErrors.length;
            }
            var valid1 = errors === errs_1;
            if (valid1 && prevValid0) {
              valid0 = false;
              passingSchemas0 = [passingSchemas0, 4];
            } else {
              if (valid1) {
                valid0 = prevValid0 = true;
                passingSchemas0 = 4;
              }
              var errs_1 = errors;
              if (!refVal36(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
                if (vErrors === null) vErrors = refVal36.errors;
                else vErrors = vErrors.concat(refVal36.errors);
                errors = vErrors.length;
              }
              var valid1 = errors === errs_1;
              if (valid1 && prevValid0) {
                valid0 = false;
                passingSchemas0 = [passingSchemas0, 5];
              } else {
                if (valid1) {
                  valid0 = prevValid0 = true;
                  passingSchemas0 = 5;
                }
              }
            }
          }
        }
      }
      if (!valid0) {
        var err = {
          keyword: 'oneOf',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/oneOf',
          params: {
            passingSchemas: passingSchemas0
          },
          message: 'should match exactly one schema in oneOf'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
        validate.errors = vErrors;
        return false;
      } else {
        errors = errs__0;
        if (vErrors !== null) {
          if (errs__0) vErrors.length = errs__0;
          else vErrors = null;
        }
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal13.schema = {
    "oneOf": [{
      "$ref": "#/definitions/answerLabel"
    }, {
      "$ref": "#/definitions/answerBoolean"
    }, {
      "$ref": "#/definitions/answerLongtext"
    }, {
      "$ref": "#/definitions/answerDiscrete"
    }, {
      "$ref": "#/definitions/answerPrescribing"
    }, {
      "$ref": "#/definitions/answerPrioritisation"
    }]
  };
  refVal13.errors = null;
  refVal[13] = refVal13;
  var refVal14 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'type'
              },
              message: 'should have required property \'type\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var schema1 = validate.schema.properties.type.enum;
            var valid1;
            valid1 = false;
            for (var i1 = 0; i1 < schema1.length; i1++)
              if (equal(data1, schema1[i1])) {
                valid1 = true;
                break;
              } if (!valid1) {
              validate.errors = [{
                keyword: 'enum',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/enum',
                params: {
                  allowedValues: schema1
                },
                message: 'should be equal to one of the allowed values'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.label === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'label'
                },
                message: 'should have required property \'label\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.label !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.label',
                  schemaPath: '#/properties/label/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.backend_id === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'backend_id'
                  },
                  message: 'should have required property \'backend_id\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data.backend_id !== "number") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.backend_id',
                    schemaPath: '#/properties/backend_id/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.visible === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (!refVal15(data.visible, (dataPath || '') + '.visible', data, 'visible', rootData)) {
                    if (vErrors === null) vErrors = refVal15.errors;
                    else vErrors = vErrors.concat(refVal15.errors);
                    errors = vErrors.length;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.indent === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.indent !== "number") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.indent',
                        schemaPath: '#/properties/indent/type',
                        params: {
                          type: 'number'
                        },
                        message: 'should be number'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.displayNumber === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.displayNumber !== "string") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.displayNumber',
                          schemaPath: '#/properties/displayNumber/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.notes === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.notes !== "string") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.notes',
                            schemaPath: '#/properties/notes/type',
                            params: {
                              type: 'string'
                            },
                            message: 'should be string'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal14.schema = {
    "type": "object",
    "required": ["type", "label", "backend_id"],
    "properties": {
      "type": {
        "type": "string",
        "enum": ["label"]
      },
      "label": {
        "type": "string"
      },
      "backend_id": {
        "type": "number"
      },
      "visible": {
        "$ref": "#/definitions/expr"
      },
      "indent": {
        "type": "number"
      },
      "displayNumber": {
        "type": "string"
      },
      "notes": {
        "type": "string"
      }
    }
  };
  refVal14.errors = null;
  refVal[14] = refVal14;
  var refVal15 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      var errs__0 = errors,
        prevValid0 = false,
        valid0 = false,
        passingSchemas0 = null;
      var errs_1 = errors;
      if (!refVal16(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
        if (vErrors === null) vErrors = refVal16.errors;
        else vErrors = vErrors.concat(refVal16.errors);
        errors = vErrors.length;
      }
      var valid1 = errors === errs_1;
      if (valid1) {
        valid0 = prevValid0 = true;
        passingSchemas0 = 0;
      }
      var errs_1 = errors;
      if (!refVal20(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
        if (vErrors === null) vErrors = refVal20.errors;
        else vErrors = vErrors.concat(refVal20.errors);
        errors = vErrors.length;
      }
      var valid1 = errors === errs_1;
      if (valid1 && prevValid0) {
        valid0 = false;
        passingSchemas0 = [passingSchemas0, 1];
      } else {
        if (valid1) {
          valid0 = prevValid0 = true;
          passingSchemas0 = 1;
        }
        var errs_1 = errors;
        if (!refVal21(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
          if (vErrors === null) vErrors = refVal21.errors;
          else vErrors = vErrors.concat(refVal21.errors);
          errors = vErrors.length;
        }
        var valid1 = errors === errs_1;
        if (valid1 && prevValid0) {
          valid0 = false;
          passingSchemas0 = [passingSchemas0, 2];
        } else {
          if (valid1) {
            valid0 = prevValid0 = true;
            passingSchemas0 = 2;
          }
          var errs_1 = errors;
          if (!refVal22(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
            if (vErrors === null) vErrors = refVal22.errors;
            else vErrors = vErrors.concat(refVal22.errors);
            errors = vErrors.length;
          }
          var valid1 = errors === errs_1;
          if (valid1 && prevValid0) {
            valid0 = false;
            passingSchemas0 = [passingSchemas0, 3];
          } else {
            if (valid1) {
              valid0 = prevValid0 = true;
              passingSchemas0 = 3;
            }
            var errs_1 = errors;
            if (!refVal23(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
              if (vErrors === null) vErrors = refVal23.errors;
              else vErrors = vErrors.concat(refVal23.errors);
              errors = vErrors.length;
            }
            var valid1 = errors === errs_1;
            if (valid1 && prevValid0) {
              valid0 = false;
              passingSchemas0 = [passingSchemas0, 4];
            } else {
              if (valid1) {
                valid0 = prevValid0 = true;
                passingSchemas0 = 4;
              }
              var errs_1 = errors;
              if (!refVal24(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
                if (vErrors === null) vErrors = refVal24.errors;
                else vErrors = vErrors.concat(refVal24.errors);
                errors = vErrors.length;
              }
              var valid1 = errors === errs_1;
              if (valid1 && prevValid0) {
                valid0 = false;
                passingSchemas0 = [passingSchemas0, 5];
              } else {
                if (valid1) {
                  valid0 = prevValid0 = true;
                  passingSchemas0 = 5;
                }
                var errs_1 = errors;
                if (!refVal25(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
                  if (vErrors === null) vErrors = refVal25.errors;
                  else vErrors = vErrors.concat(refVal25.errors);
                  errors = vErrors.length;
                }
                var valid1 = errors === errs_1;
                if (valid1 && prevValid0) {
                  valid0 = false;
                  passingSchemas0 = [passingSchemas0, 6];
                } else {
                  if (valid1) {
                    valid0 = prevValid0 = true;
                    passingSchemas0 = 6;
                  }
                  var errs_1 = errors;
                  if (!refVal26(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
                    if (vErrors === null) vErrors = refVal26.errors;
                    else vErrors = vErrors.concat(refVal26.errors);
                    errors = vErrors.length;
                  }
                  var valid1 = errors === errs_1;
                  if (valid1 && prevValid0) {
                    valid0 = false;
                    passingSchemas0 = [passingSchemas0, 7];
                  } else {
                    if (valid1) {
                      valid0 = prevValid0 = true;
                      passingSchemas0 = 7;
                    }
                    var errs_1 = errors;
                    if (!refVal27(data, (dataPath || ''), parentData, parentDataProperty, rootData)) {
                      if (vErrors === null) vErrors = refVal27.errors;
                      else vErrors = vErrors.concat(refVal27.errors);
                      errors = vErrors.length;
                    }
                    var valid1 = errors === errs_1;
                    if (valid1 && prevValid0) {
                      valid0 = false;
                      passingSchemas0 = [passingSchemas0, 8];
                    } else {
                      if (valid1) {
                        valid0 = prevValid0 = true;
                        passingSchemas0 = 8;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (!valid0) {
        var err = {
          keyword: 'oneOf',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/oneOf',
          params: {
            passingSchemas: passingSchemas0
          },
          message: 'should match exactly one schema in oneOf'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
        validate.errors = vErrors;
        return false;
      } else {
        errors = errs__0;
        if (vErrors !== null) {
          if (errs__0) vErrors.length = errs__0;
          else vErrors = null;
        }
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal15.schema = {
    "oneOf": [{
      "$ref": "#/definitions/exprEq"
    }, {
      "$ref": "#/definitions/exprGt"
    }, {
      "$ref": "#/definitions/exprLt"
    }, {
      "$ref": "#/definitions/exprGe"
    }, {
      "$ref": "#/definitions/exprLe"
    }, {
      "$ref": "#/definitions/exprNot"
    }, {
      "$ref": "#/definitions/exprAnd"
    }, {
      "$ref": "#/definitions/exprOr"
    }, {
      "$ref": "#/definitions/exprXor"
    }]
  };
  refVal15.errors = null;
  refVal[15] = refVal15;
  var refVal16 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.eq;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'eq'
              },
              message: 'should have required property \'eq\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              if (data1.length > 2) {
                validate.errors = [{
                  keyword: 'maxItems',
                  dataPath: (dataPath || '') + '.eq',
                  schemaPath: '#/properties/eq/maxItems',
                  params: {
                    limit: 2
                  },
                  message: 'should NOT have more than 2 items'
                }];
                return false;
              } else {
                if (data1.length < 2) {
                  validate.errors = [{
                    keyword: 'minItems',
                    dataPath: (dataPath || '') + '.eq',
                    schemaPath: '#/properties/eq/minItems',
                    params: {
                      limit: 2
                    },
                    message: 'should NOT have fewer than 2 items'
                  }];
                  return false;
                } else {
                  var errs__1 = errors;
                  var valid1;
                  valid2 = true;
                  if (data1.length > 0) {
                    var errs_2 = errors;
                    if (!refVal17(data1[0], (dataPath || '') + '.eq[' + 0 + ']', data1, 0, rootData)) {
                      if (vErrors === null) vErrors = refVal17.errors;
                      else vErrors = vErrors.concat(refVal17.errors);
                      errors = vErrors.length;
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    valid2 = true;
                    if (data1.length > 1) {
                      var errs_2 = errors;
                      if (!refVal[17](data1[1], (dataPath || '') + '.eq[' + 1 + ']', data1, 1, rootData)) {
                        if (vErrors === null) vErrors = refVal[17].errors;
                        else vErrors = vErrors.concat(refVal[17].errors);
                        errors = vErrors.length;
                      }
                      var valid2 = errors === errs_2;
                    }
                  }
                }
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.eq',
                schemaPath: '#/properties/eq/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal16.schema = {
    "type": "object",
    "required": ["eq"],
    "properties": {
      "eq": {
        "type": "array",
        "minItems": 2,
        "maxItems": 2,
        "items": [{
          "$ref": "#/definitions/exprObject"
        }, {
          "$ref": "#/definitions/exprObject"
        }]
      }
    }
  };
  refVal16.errors = null;
  refVal[16] = refVal16;
  var refVal17 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      var errs__0 = errors,
        prevValid0 = false,
        valid0 = false,
        passingSchemas0 = null;
      var errs_1 = errors;
      var errs_2 = errors;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__2 = errors;
          var valid3 = true;
          if (data.backend_id === undefined) {
            valid3 = false;
            var err = {
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/definitions/exprRef/required',
              params: {
                missingProperty: 'backend_id'
              },
              message: 'should have required property \'backend_id\''
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          } else {
            var errs_3 = errors;
            if (typeof data.backend_id !== "number") {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '.backend_id',
                schemaPath: '#/definitions/exprRef/properties/backend_id/type',
                params: {
                  type: 'number'
                },
                message: 'should be number'
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            var valid3 = errors === errs_3;
          }
        }
      } else {
        var err = {
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/definitions/exprRef/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
      }
      var valid2 = errors === errs_2;
      var valid1 = errors === errs_1;
      if (valid1) {
        valid0 = prevValid0 = true;
        passingSchemas0 = 0;
      }
      var errs_1 = errors;
      var errs_2 = errors;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__2 = errors;
          var valid3 = true;
          if (data.value === undefined) {
            valid3 = false;
            var err = {
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/definitions/exprVal/required',
              params: {
                missingProperty: 'value'
              },
              message: 'should have required property \'value\''
            };
            if (vErrors === null) vErrors = [err];
            else vErrors.push(err);
            errors++;
          } else {
            var errs_3 = errors;
            if (typeof data.value !== "string") {
              var err = {
                keyword: 'type',
                dataPath: (dataPath || '') + '.value',
                schemaPath: '#/definitions/exprVal/properties/value/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              };
              if (vErrors === null) vErrors = [err];
              else vErrors.push(err);
              errors++;
            }
            var valid3 = errors === errs_3;
          }
        }
      } else {
        var err = {
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/definitions/exprVal/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
      }
      var valid2 = errors === errs_2;
      var valid1 = errors === errs_1;
      if (valid1 && prevValid0) {
        valid0 = false;
        passingSchemas0 = [passingSchemas0, 1];
      } else {
        if (valid1) {
          valid0 = prevValid0 = true;
          passingSchemas0 = 1;
        }
      }
      if (!valid0) {
        var err = {
          keyword: 'oneOf',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/oneOf',
          params: {
            passingSchemas: passingSchemas0
          },
          message: 'should match exactly one schema in oneOf'
        };
        if (vErrors === null) vErrors = [err];
        else vErrors.push(err);
        errors++;
        validate.errors = vErrors;
        return false;
      } else {
        errors = errs__0;
        if (vErrors !== null) {
          if (errs__0) vErrors.length = errs__0;
          else vErrors = null;
        }
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal17.schema = {
    "oneOf": [{
      "$ref": "#/definitions/exprRef"
    }, {
      "$ref": "#/definitions/exprVal"
    }]
  };
  refVal17.errors = null;
  refVal[17] = refVal17;
  var refVal18 = {
    "type": "object",
    "required": ["backend_id"],
    "properties": {
      "backend_id": {
        "type": "number"
      }
    }
  };
  refVal[18] = refVal18;
  var refVal19 = {
    "type": "object",
    "required": ["value"],
    "properties": {
      "value": {
        "type": "string"
      }
    }
  };
  refVal[19] = refVal19;
  var refVal20 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.gt;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'gt'
              },
              message: 'should have required property \'gt\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              if (data1.length > 2) {
                validate.errors = [{
                  keyword: 'maxItems',
                  dataPath: (dataPath || '') + '.gt',
                  schemaPath: '#/properties/gt/maxItems',
                  params: {
                    limit: 2
                  },
                  message: 'should NOT have more than 2 items'
                }];
                return false;
              } else {
                if (data1.length < 2) {
                  validate.errors = [{
                    keyword: 'minItems',
                    dataPath: (dataPath || '') + '.gt',
                    schemaPath: '#/properties/gt/minItems',
                    params: {
                      limit: 2
                    },
                    message: 'should NOT have fewer than 2 items'
                  }];
                  return false;
                } else {
                  var errs__1 = errors;
                  var valid1;
                  valid2 = true;
                  if (data1.length > 0) {
                    var errs_2 = errors;
                    if (!refVal[17](data1[0], (dataPath || '') + '.gt[' + 0 + ']', data1, 0, rootData)) {
                      if (vErrors === null) vErrors = refVal[17].errors;
                      else vErrors = vErrors.concat(refVal[17].errors);
                      errors = vErrors.length;
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    valid2 = true;
                    if (data1.length > 1) {
                      var errs_2 = errors;
                      if (!refVal[17](data1[1], (dataPath || '') + '.gt[' + 1 + ']', data1, 1, rootData)) {
                        if (vErrors === null) vErrors = refVal[17].errors;
                        else vErrors = vErrors.concat(refVal[17].errors);
                        errors = vErrors.length;
                      }
                      var valid2 = errors === errs_2;
                    }
                  }
                }
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.gt',
                schemaPath: '#/properties/gt/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal20.schema = {
    "type": "object",
    "required": ["gt"],
    "properties": {
      "gt": {
        "type": "array",
        "minItems": 2,
        "maxItems": 2,
        "items": [{
          "$ref": "#/definitions/exprObject"
        }, {
          "$ref": "#/definitions/exprObject"
        }]
      }
    }
  };
  refVal20.errors = null;
  refVal[20] = refVal20;
  var refVal21 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.lt;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'lt'
              },
              message: 'should have required property \'lt\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              if (data1.length > 2) {
                validate.errors = [{
                  keyword: 'maxItems',
                  dataPath: (dataPath || '') + '.lt',
                  schemaPath: '#/properties/lt/maxItems',
                  params: {
                    limit: 2
                  },
                  message: 'should NOT have more than 2 items'
                }];
                return false;
              } else {
                if (data1.length < 2) {
                  validate.errors = [{
                    keyword: 'minItems',
                    dataPath: (dataPath || '') + '.lt',
                    schemaPath: '#/properties/lt/minItems',
                    params: {
                      limit: 2
                    },
                    message: 'should NOT have fewer than 2 items'
                  }];
                  return false;
                } else {
                  var errs__1 = errors;
                  var valid1;
                  valid2 = true;
                  if (data1.length > 0) {
                    var errs_2 = errors;
                    if (!refVal[17](data1[0], (dataPath || '') + '.lt[' + 0 + ']', data1, 0, rootData)) {
                      if (vErrors === null) vErrors = refVal[17].errors;
                      else vErrors = vErrors.concat(refVal[17].errors);
                      errors = vErrors.length;
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    valid2 = true;
                    if (data1.length > 1) {
                      var errs_2 = errors;
                      if (!refVal[17](data1[1], (dataPath || '') + '.lt[' + 1 + ']', data1, 1, rootData)) {
                        if (vErrors === null) vErrors = refVal[17].errors;
                        else vErrors = vErrors.concat(refVal[17].errors);
                        errors = vErrors.length;
                      }
                      var valid2 = errors === errs_2;
                    }
                  }
                }
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.lt',
                schemaPath: '#/properties/lt/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal21.schema = {
    "type": "object",
    "required": ["lt"],
    "properties": {
      "lt": {
        "type": "array",
        "minItems": 2,
        "maxItems": 2,
        "items": [{
          "$ref": "#/definitions/exprObject"
        }, {
          "$ref": "#/definitions/exprObject"
        }]
      }
    }
  };
  refVal21.errors = null;
  refVal[21] = refVal21;
  var refVal22 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.ge;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'ge'
              },
              message: 'should have required property \'ge\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              if (data1.length > 2) {
                validate.errors = [{
                  keyword: 'maxItems',
                  dataPath: (dataPath || '') + '.ge',
                  schemaPath: '#/properties/ge/maxItems',
                  params: {
                    limit: 2
                  },
                  message: 'should NOT have more than 2 items'
                }];
                return false;
              } else {
                if (data1.length < 2) {
                  validate.errors = [{
                    keyword: 'minItems',
                    dataPath: (dataPath || '') + '.ge',
                    schemaPath: '#/properties/ge/minItems',
                    params: {
                      limit: 2
                    },
                    message: 'should NOT have fewer than 2 items'
                  }];
                  return false;
                } else {
                  var errs__1 = errors;
                  var valid1;
                  valid2 = true;
                  if (data1.length > 0) {
                    var errs_2 = errors;
                    if (!refVal[17](data1[0], (dataPath || '') + '.ge[' + 0 + ']', data1, 0, rootData)) {
                      if (vErrors === null) vErrors = refVal[17].errors;
                      else vErrors = vErrors.concat(refVal[17].errors);
                      errors = vErrors.length;
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    valid2 = true;
                    if (data1.length > 1) {
                      var errs_2 = errors;
                      if (!refVal[17](data1[1], (dataPath || '') + '.ge[' + 1 + ']', data1, 1, rootData)) {
                        if (vErrors === null) vErrors = refVal[17].errors;
                        else vErrors = vErrors.concat(refVal[17].errors);
                        errors = vErrors.length;
                      }
                      var valid2 = errors === errs_2;
                    }
                  }
                }
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.ge',
                schemaPath: '#/properties/ge/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal22.schema = {
    "type": "object",
    "required": ["ge"],
    "properties": {
      "ge": {
        "type": "array",
        "minItems": 2,
        "maxItems": 2,
        "items": [{
          "$ref": "#/definitions/exprObject"
        }, {
          "$ref": "#/definitions/exprObject"
        }]
      }
    }
  };
  refVal22.errors = null;
  refVal[22] = refVal22;
  var refVal23 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.le;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'le'
              },
              message: 'should have required property \'le\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              if (data1.length > 2) {
                validate.errors = [{
                  keyword: 'maxItems',
                  dataPath: (dataPath || '') + '.le',
                  schemaPath: '#/properties/le/maxItems',
                  params: {
                    limit: 2
                  },
                  message: 'should NOT have more than 2 items'
                }];
                return false;
              } else {
                if (data1.length < 2) {
                  validate.errors = [{
                    keyword: 'minItems',
                    dataPath: (dataPath || '') + '.le',
                    schemaPath: '#/properties/le/minItems',
                    params: {
                      limit: 2
                    },
                    message: 'should NOT have fewer than 2 items'
                  }];
                  return false;
                } else {
                  var errs__1 = errors;
                  var valid1;
                  valid2 = true;
                  if (data1.length > 0) {
                    var errs_2 = errors;
                    if (!refVal[17](data1[0], (dataPath || '') + '.le[' + 0 + ']', data1, 0, rootData)) {
                      if (vErrors === null) vErrors = refVal[17].errors;
                      else vErrors = vErrors.concat(refVal[17].errors);
                      errors = vErrors.length;
                    }
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {
                    valid2 = true;
                    if (data1.length > 1) {
                      var errs_2 = errors;
                      if (!refVal[17](data1[1], (dataPath || '') + '.le[' + 1 + ']', data1, 1, rootData)) {
                        if (vErrors === null) vErrors = refVal[17].errors;
                        else vErrors = vErrors.concat(refVal[17].errors);
                        errors = vErrors.length;
                      }
                      var valid2 = errors === errs_2;
                    }
                  }
                }
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.le',
                schemaPath: '#/properties/le/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal23.schema = {
    "type": "object",
    "required": ["le"],
    "properties": {
      "le": {
        "type": "array",
        "minItems": 2,
        "maxItems": 2,
        "items": [{
          "$ref": "#/definitions/exprObject"
        }, {
          "$ref": "#/definitions/exprObject"
        }]
      }
    }
  };
  refVal23.errors = null;
  refVal[23] = refVal23;
  var refVal24 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          if (data.not === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'not'
              },
              message: 'should have required property \'not\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (!refVal[15](data.not, (dataPath || '') + '.not', data, 'not', rootData)) {
              if (vErrors === null) vErrors = refVal[15].errors;
              else vErrors = vErrors.concat(refVal[15].errors);
              errors = vErrors.length;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal24.schema = {
    "type": "object",
    "required": ["not"],
    "properties": {
      "not": {
        "$ref": "#/definitions/expr"
      }
    }
  };
  refVal24.errors = null;
  refVal[24] = refVal24;
  var refVal25 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.and;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'and'
              },
              message: 'should have required property \'and\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              if (data1.length < 2) {
                validate.errors = [{
                  keyword: 'minItems',
                  dataPath: (dataPath || '') + '.and',
                  schemaPath: '#/properties/and/minItems',
                  params: {
                    limit: 2
                  },
                  message: 'should NOT have fewer than 2 items'
                }];
                return false;
              } else {
                var errs__1 = errors;
                var valid1;
                valid2 = true;
                if (data1.length > 0) {
                  var errs_2 = errors;
                  if (!refVal[15](data1[0], (dataPath || '') + '.and[' + 0 + ']', data1, 0, rootData)) {
                    if (vErrors === null) vErrors = refVal[15].errors;
                    else vErrors = vErrors.concat(refVal[15].errors);
                    errors = vErrors.length;
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  valid2 = true;
                  if (data1.length > 1) {
                    var errs_2 = errors;
                    if (!refVal[15](data1[1], (dataPath || '') + '.and[' + 1 + ']', data1, 1, rootData)) {
                      if (vErrors === null) vErrors = refVal[15].errors;
                      else vErrors = vErrors.concat(refVal[15].errors);
                      errors = vErrors.length;
                    }
                    var valid2 = errors === errs_2;
                  }
                }
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.and',
                schemaPath: '#/properties/and/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal25.schema = {
    "type": "object",
    "required": ["and"],
    "properties": {
      "and": {
        "type": "array",
        "minItems": 2,
        "items": [{
          "$ref": "#/definitions/expr"
        }, {
          "$ref": "#/definitions/expr"
        }]
      }
    }
  };
  refVal25.errors = null;
  refVal[25] = refVal25;
  var refVal26 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.or;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'or'
              },
              message: 'should have required property \'or\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              if (data1.length < 2) {
                validate.errors = [{
                  keyword: 'minItems',
                  dataPath: (dataPath || '') + '.or',
                  schemaPath: '#/properties/or/minItems',
                  params: {
                    limit: 2
                  },
                  message: 'should NOT have fewer than 2 items'
                }];
                return false;
              } else {
                var errs__1 = errors;
                var valid1;
                valid2 = true;
                if (data1.length > 0) {
                  var errs_2 = errors;
                  if (!refVal[15](data1[0], (dataPath || '') + '.or[' + 0 + ']', data1, 0, rootData)) {
                    if (vErrors === null) vErrors = refVal[15].errors;
                    else vErrors = vErrors.concat(refVal[15].errors);
                    errors = vErrors.length;
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  valid2 = true;
                  if (data1.length > 1) {
                    var errs_2 = errors;
                    if (!refVal[15](data1[1], (dataPath || '') + '.or[' + 1 + ']', data1, 1, rootData)) {
                      if (vErrors === null) vErrors = refVal[15].errors;
                      else vErrors = vErrors.concat(refVal[15].errors);
                      errors = vErrors.length;
                    }
                    var valid2 = errors === errs_2;
                  }
                }
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.or',
                schemaPath: '#/properties/or/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal26.schema = {
    "type": "object",
    "required": ["or"],
    "properties": {
      "or": {
        "type": "array",
        "minItems": 2,
        "items": [{
          "$ref": "#/definitions/expr"
        }, {
          "$ref": "#/definitions/expr"
        }]
      }
    }
  };
  refVal26.errors = null;
  refVal[26] = refVal26;
  var refVal27 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.xor;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'xor'
              },
              message: 'should have required property \'xor\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              if (data1.length < 2) {
                validate.errors = [{
                  keyword: 'minItems',
                  dataPath: (dataPath || '') + '.xor',
                  schemaPath: '#/properties/xor/minItems',
                  params: {
                    limit: 2
                  },
                  message: 'should NOT have fewer than 2 items'
                }];
                return false;
              } else {
                var errs__1 = errors;
                var valid1;
                valid2 = true;
                if (data1.length > 0) {
                  var errs_2 = errors;
                  if (!refVal[15](data1[0], (dataPath || '') + '.xor[' + 0 + ']', data1, 0, rootData)) {
                    if (vErrors === null) vErrors = refVal[15].errors;
                    else vErrors = vErrors.concat(refVal[15].errors);
                    errors = vErrors.length;
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  valid2 = true;
                  if (data1.length > 1) {
                    var errs_2 = errors;
                    if (!refVal[15](data1[1], (dataPath || '') + '.xor[' + 1 + ']', data1, 1, rootData)) {
                      if (vErrors === null) vErrors = refVal[15].errors;
                      else vErrors = vErrors.concat(refVal[15].errors);
                      errors = vErrors.length;
                    }
                    var valid2 = errors === errs_2;
                  }
                }
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.xor',
                schemaPath: '#/properties/xor/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal27.schema = {
    "type": "object",
    "required": ["xor"],
    "properties": {
      "xor": {
        "type": "array",
        "minItems": 2,
        "items": [{
          "$ref": "#/definitions/expr"
        }, {
          "$ref": "#/definitions/expr"
        }]
      }
    }
  };
  refVal27.errors = null;
  refVal[27] = refVal27;
  var refVal28 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'type'
              },
              message: 'should have required property \'type\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var schema1 = validate.schema.properties.type.enum;
            var valid1;
            valid1 = false;
            for (var i1 = 0; i1 < schema1.length; i1++)
              if (equal(data1, schema1[i1])) {
                valid1 = true;
                break;
              } if (!valid1) {
              validate.errors = [{
                keyword: 'enum',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/enum',
                params: {
                  allowedValues: schema1
                },
                message: 'should be equal to one of the allowed values'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.label === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'label'
                },
                message: 'should have required property \'label\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.label !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.label',
                  schemaPath: '#/properties/label/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.backend_id === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'backend_id'
                  },
                  message: 'should have required property \'backend_id\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data.backend_id !== "number") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.backend_id',
                    schemaPath: '#/properties/backend_id/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.visible === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (!refVal[15](data.visible, (dataPath || '') + '.visible', data, 'visible', rootData)) {
                    if (vErrors === null) vErrors = refVal[15].errors;
                    else vErrors = vErrors.concat(refVal[15].errors);
                    errors = vErrors.length;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.valid === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (!refVal[15](data.valid, (dataPath || '') + '.valid', data, 'valid', rootData)) {
                      if (vErrors === null) vErrors = refVal[15].errors;
                      else vErrors = vErrors.concat(refVal[15].errors);
                      errors = vErrors.length;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.indent === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.indent !== "number") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.indent',
                          schemaPath: '#/properties/indent/type',
                          params: {
                            type: 'number'
                          },
                          message: 'should be number'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.displayNumber === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.displayNumber !== "string") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.displayNumber',
                            schemaPath: '#/properties/displayNumber/type',
                            params: {
                              type: 'string'
                            },
                            message: 'should be string'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.notes === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.notes !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.notes',
                              schemaPath: '#/properties/notes/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal28.schema = {
    "type": "object",
    "required": ["type", "label", "backend_id"],
    "properties": {
      "type": {
        "type": "string",
        "enum": ["boolean"]
      },
      "label": {
        "type": "string"
      },
      "backend_id": {
        "type": "number"
      },
      "visible": {
        "$ref": "#/definitions/expr"
      },
      "valid": {
        "$ref": "#/definitions/expr"
      },
      "indent": {
        "type": "number"
      },
      "displayNumber": {
        "type": "string"
      },
      "notes": {
        "type": "string"
      }
    }
  };
  refVal28.errors = null;
  refVal[28] = refVal28;
  var refVal29 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'type'
              },
              message: 'should have required property \'type\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var schema1 = validate.schema.properties.type.enum;
            var valid1;
            valid1 = false;
            for (var i1 = 0; i1 < schema1.length; i1++)
              if (equal(data1, schema1[i1])) {
                valid1 = true;
                break;
              } if (!valid1) {
              validate.errors = [{
                keyword: 'enum',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/enum',
                params: {
                  allowedValues: schema1
                },
                message: 'should be equal to one of the allowed values'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.label === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'label'
                },
                message: 'should have required property \'label\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.label !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.label',
                  schemaPath: '#/properties/label/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.backend_id === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'backend_id'
                  },
                  message: 'should have required property \'backend_id\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data.backend_id !== "number") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.backend_id',
                    schemaPath: '#/properties/backend_id/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.minimumLines;
                if (data1 === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.minimumLines',
                      schemaPath: '#/properties/minimumLines/type',
                      params: {
                        type: 'integer'
                      },
                      message: 'should be integer'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.short === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.short !== "boolean") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.short',
                        schemaPath: '#/properties/short/type',
                        params: {
                          type: 'boolean'
                        },
                        message: 'should be boolean'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.visible === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (!refVal[15](data.visible, (dataPath || '') + '.visible', data, 'visible', rootData)) {
                        if (vErrors === null) vErrors = refVal[15].errors;
                        else vErrors = vErrors.concat(refVal[15].errors);
                        errors = vErrors.length;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.valid === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (!refVal[15](data.valid, (dataPath || '') + '.valid', data, 'valid', rootData)) {
                          if (vErrors === null) vErrors = refVal[15].errors;
                          else vErrors = vErrors.concat(refVal[15].errors);
                          errors = vErrors.length;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.indent === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.indent !== "number") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.indent',
                              schemaPath: '#/properties/indent/type',
                              params: {
                                type: 'number'
                              },
                              message: 'should be number'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.displayNumber === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.displayNumber !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.displayNumber',
                                schemaPath: '#/properties/displayNumber/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.notes === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.notes !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.notes',
                                  schemaPath: '#/properties/notes/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal29.schema = {
    "type": "object",
    "required": ["type", "label", "backend_id"],
    "properties": {
      "type": {
        "type": "string",
        "enum": ["longtext", "text"]
      },
      "label": {
        "type": "string"
      },
      "backend_id": {
        "type": "number"
      },
      "minimumLines": {
        "type": "integer"
      },
      "short": {
        "type": "boolean"
      },
      "visible": {
        "$ref": "#/definitions/expr"
      },
      "valid": {
        "$ref": "#/definitions/expr"
      },
      "indent": {
        "type": "number"
      },
      "displayNumber": {
        "type": "string"
      },
      "notes": {
        "type": "string"
      }
    }
  };
  refVal29.errors = null;
  refVal[29] = refVal29;
  var refVal30 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'type'
              },
              message: 'should have required property \'type\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var schema1 = validate.schema.properties.type.enum;
            var valid1;
            valid1 = false;
            for (var i1 = 0; i1 < schema1.length; i1++)
              if (equal(data1, schema1[i1])) {
                valid1 = true;
                break;
              } if (!valid1) {
              validate.errors = [{
                keyword: 'enum',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/enum',
                params: {
                  allowedValues: schema1
                },
                message: 'should be equal to one of the allowed values'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.label === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'label'
                },
                message: 'should have required property \'label\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.label !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.label',
                  schemaPath: '#/properties/label/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.backend_id === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'backend_id'
                  },
                  message: 'should have required property \'backend_id\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data.backend_id !== "number") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.backend_id',
                    schemaPath: '#/properties/backend_id/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.options;
                if (data1 === undefined) {
                  valid1 = false;
                  validate.errors = [{
                    keyword: 'required',
                    dataPath: (dataPath || '') + "",
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'options'
                    },
                    message: 'should have required property \'options\''
                  }];
                  return false;
                } else {
                  var errs_1 = errors;
                  if (Array.isArray(data1)) {
                    var errs__1 = errors;
                    var valid1;
                    for (var i1 = 0; i1 < data1.length; i1++) {
                      var errs_2 = errors;
                      if (typeof data1[i1] !== "string") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.options[' + i1 + ']',
                          schemaPath: '#/properties/options/items/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                      var valid2 = errors === errs_2;
                      if (!valid2) break;
                    }
                  } else {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.options',
                      schemaPath: '#/properties/options/type',
                      params: {
                        type: 'array'
                      },
                      message: 'should be array'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.optionDetails;
                  if (data1 === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (Array.isArray(data1)) {
                      var errs__1 = errors;
                      var valid1;
                      for (var i1 = 0; i1 < data1.length; i1++) {
                        var data2 = data1[i1];
                        var errs_2 = errors;
                        var errs_3 = errors;
                        if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                          var errs__3 = errors;
                          var valid4 = true;
                          if (data2.backend_id === undefined) {
                            valid4 = true;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2.backend_id !== "number") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.optionDetails[' + i1 + '].backend_id',
                                schemaPath: '#/definitions/answerOptionDetails/properties/backend_id/type',
                                params: {
                                  type: 'number'
                                },
                                message: 'should be number'
                              }];
                              return false;
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            if (data2.description === undefined) {
                              valid4 = true;
                            } else {
                              var errs_4 = errors;
                              if (typeof data2.description !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.optionDetails[' + i1 + '].description',
                                  schemaPath: '#/definitions/answerOptionDetails/properties/description/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid4 = errors === errs_4;
                            }
                          }
                        } else {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.optionDetails[' + i1 + ']',
                            schemaPath: '#/definitions/answerOptionDetails/type',
                            params: {
                              type: 'object'
                            },
                            message: 'should be object'
                          }];
                          return false;
                        }
                        var valid3 = errors === errs_3;
                        var valid2 = errors === errs_2;
                        if (!valid2) break;
                      }
                    } else {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.optionDetails',
                        schemaPath: '#/properties/optionDetails/type',
                        params: {
                          type: 'array'
                        },
                        message: 'should be array'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.visible === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (!refVal[15](data.visible, (dataPath || '') + '.visible', data, 'visible', rootData)) {
                        if (vErrors === null) vErrors = refVal[15].errors;
                        else vErrors = vErrors.concat(refVal[15].errors);
                        errors = vErrors.length;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.valid === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (!refVal[15](data.valid, (dataPath || '') + '.valid', data, 'valid', rootData)) {
                          if (vErrors === null) vErrors = refVal[15].errors;
                          else vErrors = vErrors.concat(refVal[15].errors);
                          errors = vErrors.length;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.indent === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.indent !== "number") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.indent',
                              schemaPath: '#/properties/indent/type',
                              params: {
                                type: 'number'
                              },
                              message: 'should be number'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          var data1 = data.candidateToOptionOrder;
                          if (data1 === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
                              var errs__1 = errors;
                              var valid2 = true;
                              for (var key1 in data1) {
                                var data2 = data1[key1];
                                var errs_2 = errors;
                                var errs_3 = errors;
                                if (Array.isArray(data2)) {
                                  var errs__3 = errors;
                                  var valid3;
                                  for (var i3 = 0; i3 < data2.length; i3++) {
                                    var errs_4 = errors;
                                    if (typeof data2[i3] !== "number") {
                                      validate.errors = [{
                                        keyword: 'type',
                                        dataPath: (dataPath || '') + '.candidateToOptionOrder[\'' + key1 + '\'][' + i3 + ']',
                                        schemaPath: '#/definitions/answerOptionOrder/items/type',
                                        params: {
                                          type: 'number'
                                        },
                                        message: 'should be number'
                                      }];
                                      return false;
                                    }
                                    var valid4 = errors === errs_4;
                                    if (!valid4) break;
                                  }
                                } else {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.candidateToOptionOrder[\'' + key1 + '\']',
                                    schemaPath: '#/definitions/answerOptionOrder/type',
                                    params: {
                                      type: 'array'
                                    },
                                    message: 'should be array'
                                  }];
                                  return false;
                                }
                                var valid3 = errors === errs_3;
                                var valid2 = errors === errs_2;
                                if (!valid2) break;
                              }
                            } else {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.candidateToOptionOrder',
                                schemaPath: '#/properties/candidateToOptionOrder/type',
                                params: {
                                  type: 'object'
                                },
                                message: 'should be object'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.displayNumber === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.displayNumber !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.displayNumber',
                                  schemaPath: '#/properties/displayNumber/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.notes === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.notes !== "string") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.notes',
                                    schemaPath: '#/properties/notes/type',
                                    params: {
                                      type: 'string'
                                    },
                                    message: 'should be string'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal30.schema = {
    "type": "object",
    "required": ["type", "label", "backend_id", "options"],
    "properties": {
      "type": {
        "type": "string",
        "enum": ["dropdown", "SBA"]
      },
      "label": {
        "type": "string"
      },
      "backend_id": {
        "type": "number"
      },
      "options": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "optionDetails": {
        "type": "array",
        "items": {
          "$ref": "#/definitions/answerOptionDetails"
        }
      },
      "visible": {
        "$ref": "#/definitions/expr"
      },
      "valid": {
        "$ref": "#/definitions/expr"
      },
      "indent": {
        "type": "number"
      },
      "candidateToOptionOrder": {
        "type": "object",
        "description": "map candidate-id to option-order",
        "additionalProperties": {
          "$ref": "#/definitions/answerOptionOrder"
        }
      },
      "displayNumber": {
        "type": "string"
      },
      "notes": {
        "type": "string"
      }
    }
  };
  refVal30.errors = null;
  refVal[30] = refVal30;
  var refVal31 = {
    "type": "object",
    "properties": {
      "backend_id": {
        "type": "number"
      },
      "description": {
        "type": "string"
      }
    }
  };
  refVal[31] = refVal31;
  var refVal32 = {
    "type": "array",
    "items": {
      "type": "number"
    }
  };
  refVal[32] = refVal32;
  var refVal33 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'type'
              },
              message: 'should have required property \'type\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var schema1 = validate.schema.properties.type.enum;
            var valid1;
            valid1 = false;
            for (var i1 = 0; i1 < schema1.length; i1++)
              if (equal(data1, schema1[i1])) {
                valid1 = true;
                break;
              } if (!valid1) {
              validate.errors = [{
                keyword: 'enum',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/enum',
                params: {
                  allowedValues: schema1
                },
                message: 'should be equal to one of the allowed values'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.label === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'label'
                },
                message: 'should have required property \'label\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.label !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.label',
                  schemaPath: '#/properties/label/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.backend_id === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'backend_id'
                  },
                  message: 'should have required property \'backend_id\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data.backend_id !== "number") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.backend_id',
                    schemaPath: '#/properties/backend_id/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.options === undefined) {
                  valid1 = false;
                  validate.errors = [{
                    keyword: 'required',
                    dataPath: (dataPath || '') + "",
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'options'
                    },
                    message: 'should have required property \'options\''
                  }];
                  return false;
                } else {
                  var errs_1 = errors;
                  if (!refVal34(data.options, (dataPath || '') + '.options', data, 'options', rootData)) {
                    if (vErrors === null) vErrors = refVal34.errors;
                    else vErrors = vErrors.concat(refVal34.errors);
                    errors = vErrors.length;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.visible === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (!refVal[15](data.visible, (dataPath || '') + '.visible', data, 'visible', rootData)) {
                      if (vErrors === null) vErrors = refVal[15].errors;
                      else vErrors = vErrors.concat(refVal[15].errors);
                      errors = vErrors.length;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.indent === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.indent !== "number") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.indent',
                          schemaPath: '#/properties/indent/type',
                          params: {
                            type: 'number'
                          },
                          message: 'should be number'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.displayNumber === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.displayNumber !== "string") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.displayNumber',
                            schemaPath: '#/properties/displayNumber/type',
                            params: {
                              type: 'string'
                            },
                            message: 'should be string'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.notes === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.notes !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.notes',
                              schemaPath: '#/properties/notes/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal33.schema = {
    "type": "object",
    "required": ["type", "label", "backend_id", "options"],
    "properties": {
      "type": {
        "type": "string",
        "enum": ["pvsa"]
      },
      "label": {
        "type": "string"
      },
      "backend_id": {
        "type": "number"
      },
      "options": {
        "$ref": "#/definitions/prescribingOptionGroups"
      },
      "visible": {
        "$ref": "#/definitions/expr"
      },
      "indent": {
        "type": "number"
      },
      "displayNumber": {
        "type": "string"
      },
      "notes": {
        "type": "string"
      }
    }
  };
  refVal33.errors = null;
  refVal[33] = refVal33;
  var refVal34 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.dose;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'dose'
              },
              message: 'should have required property \'dose\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            var errs_2 = errors;
            if (Array.isArray(data1)) {
              var errs__2 = errors;
              var valid2;
              for (var i2 = 0; i2 < data1.length; i2++) {
                var data2 = data1[i2];
                var errs_3 = errors;
                if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                  if (true) {
                    var errs__3 = errors;
                    var valid4 = true;
                    var data3 = data2.options;
                    if (data3 === undefined) {
                      valid4 = false;
                      validate.errors = [{
                        keyword: 'required',
                        dataPath: (dataPath || '') + '.dose[' + i2 + ']',
                        schemaPath: '#/definitions/optionGroups/items/required',
                        params: {
                          missingProperty: 'options'
                        },
                        message: 'should have required property \'options\''
                      }];
                      return false;
                    } else {
                      var errs_4 = errors;
                      if (Array.isArray(data3)) {
                        var errs__4 = errors;
                        var valid4;
                        for (var i4 = 0; i4 < data3.length; i4++) {
                          var errs_5 = errors;
                          if (typeof data3[i4] !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.dose[' + i2 + '].options[' + i4 + ']',
                              schemaPath: '#/definitions/optionGroups/items/properties/options/items/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid5 = errors === errs_5;
                          if (!valid5) break;
                        }
                      } else {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.dose[' + i2 + '].options',
                          schemaPath: '#/definitions/optionGroups/items/properties/options/type',
                          params: {
                            type: 'array'
                          },
                          message: 'should be array'
                        }];
                        return false;
                      }
                      var valid4 = errors === errs_4;
                    }
                  }
                } else {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.dose[' + i2 + ']',
                    schemaPath: '#/definitions/optionGroups/items/type',
                    params: {
                      type: 'object'
                    },
                    message: 'should be object'
                  }];
                  return false;
                }
                var valid3 = errors === errs_3;
                if (!valid3) break;
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.dose',
                schemaPath: '#/definitions/optionGroups/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid2 = errors === errs_2;
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.route;
            if (data1 === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'route'
                },
                message: 'should have required property \'route\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              var errs_2 = errors;
              if (Array.isArray(data1)) {
                var errs__2 = errors;
                var valid2;
                for (var i2 = 0; i2 < data1.length; i2++) {
                  var data2 = data1[i2];
                  var errs_3 = errors;
                  if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                    if (true) {
                      var errs__3 = errors;
                      var valid4 = true;
                      var data3 = data2.options;
                      if (data3 === undefined) {
                        valid4 = false;
                        validate.errors = [{
                          keyword: 'required',
                          dataPath: (dataPath || '') + '.route[' + i2 + ']',
                          schemaPath: '#/definitions/optionGroups/items/required',
                          params: {
                            missingProperty: 'options'
                          },
                          message: 'should have required property \'options\''
                        }];
                        return false;
                      } else {
                        var errs_4 = errors;
                        if (Array.isArray(data3)) {
                          var errs__4 = errors;
                          var valid4;
                          for (var i4 = 0; i4 < data3.length; i4++) {
                            var errs_5 = errors;
                            if (typeof data3[i4] !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.route[' + i2 + '].options[' + i4 + ']',
                                schemaPath: '#/definitions/optionGroups/items/properties/options/items/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid5 = errors === errs_5;
                            if (!valid5) break;
                          }
                        } else {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.route[' + i2 + '].options',
                            schemaPath: '#/definitions/optionGroups/items/properties/options/type',
                            params: {
                              type: 'array'
                            },
                            message: 'should be array'
                          }];
                          return false;
                        }
                        var valid4 = errors === errs_4;
                      }
                    }
                  } else {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.route[' + i2 + ']',
                      schemaPath: '#/definitions/optionGroups/items/type',
                      params: {
                        type: 'object'
                      },
                      message: 'should be object'
                    }];
                    return false;
                  }
                  var valid3 = errors === errs_3;
                  if (!valid3) break;
                }
              } else {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.route',
                  schemaPath: '#/definitions/optionGroups/type',
                  params: {
                    type: 'array'
                  },
                  message: 'should be array'
                }];
                return false;
              }
              var valid2 = errors === errs_2;
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              var data1 = data.frequency;
              if (data1 === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'frequency'
                  },
                  message: 'should have required property \'frequency\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                var errs_2 = errors;
                if (Array.isArray(data1)) {
                  var errs__2 = errors;
                  var valid2;
                  for (var i2 = 0; i2 < data1.length; i2++) {
                    var data2 = data1[i2];
                    var errs_3 = errors;
                    if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                      if (true) {
                        var errs__3 = errors;
                        var valid4 = true;
                        var data3 = data2.options;
                        if (data3 === undefined) {
                          valid4 = false;
                          validate.errors = [{
                            keyword: 'required',
                            dataPath: (dataPath || '') + '.frequency[' + i2 + ']',
                            schemaPath: '#/definitions/optionGroups/items/required',
                            params: {
                              missingProperty: 'options'
                            },
                            message: 'should have required property \'options\''
                          }];
                          return false;
                        } else {
                          var errs_4 = errors;
                          if (Array.isArray(data3)) {
                            var errs__4 = errors;
                            var valid4;
                            for (var i4 = 0; i4 < data3.length; i4++) {
                              var errs_5 = errors;
                              if (typeof data3[i4] !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.frequency[' + i2 + '].options[' + i4 + ']',
                                  schemaPath: '#/definitions/optionGroups/items/properties/options/items/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid5 = errors === errs_5;
                              if (!valid5) break;
                            }
                          } else {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.frequency[' + i2 + '].options',
                              schemaPath: '#/definitions/optionGroups/items/properties/options/type',
                              params: {
                                type: 'array'
                              },
                              message: 'should be array'
                            }];
                            return false;
                          }
                          var valid4 = errors === errs_4;
                        }
                      }
                    } else {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.frequency[' + i2 + ']',
                        schemaPath: '#/definitions/optionGroups/items/type',
                        params: {
                          type: 'object'
                        },
                        message: 'should be object'
                      }];
                      return false;
                    }
                    var valid3 = errors === errs_3;
                    if (!valid3) break;
                  }
                } else {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.frequency',
                    schemaPath: '#/definitions/optionGroups/type',
                    params: {
                      type: 'array'
                    },
                    message: 'should be array'
                  }];
                  return false;
                }
                var valid2 = errors === errs_2;
                var valid1 = errors === errs_1;
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal34.schema = {
    "type": "object",
    "required": ["dose", "route", "frequency"],
    "properties": {
      "dose": {
        "$ref": "#/definitions/optionGroups"
      },
      "route": {
        "$ref": "#/definitions/optionGroups"
      },
      "frequency": {
        "$ref": "#/definitions/optionGroups"
      }
    }
  };
  refVal34.errors = null;
  refVal[34] = refVal34;
  var refVal35 = {
    "type": "array",
    "items": {
      "type": "object",
      "required": ["options"],
      "properties": {
        "options": {
          "type": "array",
          "items": {
            "type": "string"
          }
        }
      }
    }
  };
  refVal[35] = refVal35;
  var refVal36 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if (rootData === undefined) rootData = data;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          var data1 = data.type;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'type'
              },
              message: 'should have required property \'type\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var schema1 = validate.schema.properties.type.enum;
            var valid1;
            valid1 = false;
            for (var i1 = 0; i1 < schema1.length; i1++)
              if (equal(data1, schema1[i1])) {
                valid1 = true;
                break;
              } if (!valid1) {
              validate.errors = [{
                keyword: 'enum',
                dataPath: (dataPath || '') + '.type',
                schemaPath: '#/properties/type/enum',
                params: {
                  allowedValues: schema1
                },
                message: 'should be equal to one of the allowed values'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.label === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'label'
                },
                message: 'should have required property \'label\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.label !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.label',
                  schemaPath: '#/properties/label/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.backend_id === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'backend_id'
                  },
                  message: 'should have required property \'backend_id\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data.backend_id !== "number") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.backend_id',
                    schemaPath: '#/properties/backend_id/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.options;
                if (data1 === undefined) {
                  valid1 = false;
                  validate.errors = [{
                    keyword: 'required',
                    dataPath: (dataPath || '') + "",
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'options'
                    },
                    message: 'should have required property \'options\''
                  }];
                  return false;
                } else {
                  var errs_1 = errors;
                  if (Array.isArray(data1)) {
                    var errs__1 = errors;
                    var valid1;
                    for (var i1 = 0; i1 < data1.length; i1++) {
                      var errs_2 = errors;
                      if (typeof data1[i1] !== "string") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.options[' + i1 + ']',
                          schemaPath: '#/properties/options/items/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                      var valid2 = errors === errs_2;
                      if (!valid2) break;
                    }
                  } else {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.options',
                      schemaPath: '#/properties/options/type',
                      params: {
                        type: 'array'
                      },
                      message: 'should be array'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.optionDetails;
                  if (data1 === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (Array.isArray(data1)) {
                      var errs__1 = errors;
                      var valid1;
                      for (var i1 = 0; i1 < data1.length; i1++) {
                        var data2 = data1[i1];
                        var errs_2 = errors;
                        var errs_3 = errors;
                        if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                          var errs__3 = errors;
                          var valid4 = true;
                          if (data2.backend_id === undefined) {
                            valid4 = true;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2.backend_id !== "number") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.optionDetails[' + i1 + '].backend_id',
                                schemaPath: '#/definitions/answerOptionDetails/properties/backend_id/type',
                                params: {
                                  type: 'number'
                                },
                                message: 'should be number'
                              }];
                              return false;
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            if (data2.description === undefined) {
                              valid4 = true;
                            } else {
                              var errs_4 = errors;
                              if (typeof data2.description !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.optionDetails[' + i1 + '].description',
                                  schemaPath: '#/definitions/answerOptionDetails/properties/description/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid4 = errors === errs_4;
                            }
                          }
                        } else {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.optionDetails[' + i1 + ']',
                            schemaPath: '#/definitions/answerOptionDetails/type',
                            params: {
                              type: 'object'
                            },
                            message: 'should be object'
                          }];
                          return false;
                        }
                        var valid3 = errors === errs_3;
                        var valid2 = errors === errs_2;
                        if (!valid2) break;
                      }
                    } else {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.optionDetails',
                        schemaPath: '#/properties/optionDetails/type',
                        params: {
                          type: 'array'
                        },
                        message: 'should be array'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.visible === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (!refVal[15](data.visible, (dataPath || '') + '.visible', data, 'visible', rootData)) {
                        if (vErrors === null) vErrors = refVal[15].errors;
                        else vErrors = vErrors.concat(refVal[15].errors);
                        errors = vErrors.length;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.indent === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.indent !== "number") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.indent',
                            schemaPath: '#/properties/indent/type',
                            params: {
                              type: 'number'
                            },
                            message: 'should be number'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.displayNumber === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.displayNumber !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.displayNumber',
                              schemaPath: '#/properties/displayNumber/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.notes === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.notes !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.notes',
                                schemaPath: '#/properties/notes/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal36.schema = {
    "type": "object",
    "required": ["type", "label", "backend_id", "options"],
    "properties": {
      "type": {
        "type": "string",
        "enum": ["cpq"]
      },
      "label": {
        "type": "string"
      },
      "backend_id": {
        "type": "number"
      },
      "options": {
        "type": "array",
        "items": {
          "type": "string"
        }
      },
      "optionDetails": {
        "type": "array",
        "items": {
          "$ref": "#/definitions/answerOptionDetails"
        }
      },
      "visible": {
        "$ref": "#/definitions/expr"
      },
      "indent": {
        "type": "number"
      },
      "displayNumber": {
        "type": "string"
      },
      "notes": {
        "type": "string"
      }
    }
  };
  refVal36.errors = null;
  refVal[36] = refVal36;
  var refVal37 = (function() {
    return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
      'use strict';
      var vErrors = null;
      var errors = 0;
      if ((data && typeof data === "object" && !Array.isArray(data))) {
        if (true) {
          var errs__0 = errors;
          var valid1 = true;
          if (data.name === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.name !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.name',
                schemaPath: '#/properties/name/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.randomize === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.randomize !== "boolean") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.randomize',
                  schemaPath: '#/properties/randomize/type',
                  params: {
                    type: 'boolean'
                  },
                  message: 'should be boolean'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              var data1 = data.items;
              if (data1 === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'items'
                  },
                  message: 'should have required property \'items\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (Array.isArray(data1)) {
                  var errs__1 = errors;
                  var valid1;
                  for (var i1 = 0; i1 < data1.length; i1++) {
                    var data2 = data1[i1];
                    var errs_2 = errors;
                    var errs_3 = errors;
                    if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                      var errs__3 = errors;
                      var valid4 = true;
                      var data3 = data2.question;
                      if (data3 === undefined) {
                        valid4 = true;
                      } else {
                        var errs_4 = errors;
                        if ((typeof data3 !== "number" || (data3 % 1) || data3 !== data3)) {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.items[' + i1 + '].question',
                            schemaPath: '#/definitions/item/properties/question/type',
                            params: {
                              type: 'integer'
                            },
                            message: 'should be integer'
                          }];
                          return false;
                        }
                        var valid4 = errors === errs_4;
                      }
                      if (valid4) {
                        if (data2.factor === undefined) {
                          valid4 = true;
                        } else {
                          var errs_4 = errors;
                          if (typeof data2.factor !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.items[' + i1 + '].factor',
                              schemaPath: '#/definitions/item/properties/factor/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid4 = errors === errs_4;
                        }
                        if (valid4) {
                          if (data2.interviewId === undefined) {
                            valid4 = true;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2.interviewId !== "number") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.items[' + i1 + '].interviewId',
                                schemaPath: '#/definitions/item/properties/interviewId/type',
                                params: {
                                  type: 'number'
                                },
                                message: 'should be number'
                              }];
                              return false;
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            if (data2.round === undefined) {
                              valid4 = true;
                            } else {
                              var errs_4 = errors;
                              if (typeof data2.round !== "number") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.items[' + i1 + '].round',
                                  schemaPath: '#/definitions/item/properties/round/type',
                                  params: {
                                    type: 'number'
                                  },
                                  message: 'should be number'
                                }];
                                return false;
                              }
                              var valid4 = errors === errs_4;
                            }
                            if (valid4) {
                              if (data2.circuit === undefined) {
                                valid4 = true;
                              } else {
                                var errs_4 = errors;
                                if (typeof data2.circuit !== "number") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.items[' + i1 + '].circuit',
                                    schemaPath: '#/definitions/item/properties/circuit/type',
                                    params: {
                                      type: 'number'
                                    },
                                    message: 'should be number'
                                  }];
                                  return false;
                                }
                                var valid4 = errors === errs_4;
                              }
                              if (valid4) {
                                if (data2.room === undefined) {
                                  valid4 = true;
                                } else {
                                  var errs_4 = errors;
                                  if (typeof data2.room !== "number") {
                                    validate.errors = [{
                                      keyword: 'type',
                                      dataPath: (dataPath || '') + '.items[' + i1 + '].room',
                                      schemaPath: '#/definitions/item/properties/room/type',
                                      params: {
                                        type: 'number'
                                      },
                                      message: 'should be number'
                                    }];
                                    return false;
                                  }
                                  var valid4 = errors === errs_4;
                                }
                              }
                            }
                          }
                        }
                      }
                    } else {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.items[' + i1 + ']',
                        schemaPath: '#/definitions/item/type',
                        params: {
                          type: 'object'
                        },
                        message: 'should be object'
                      }];
                      return false;
                    }
                    var valid3 = errors === errs_3;
                    var valid2 = errors === errs_2;
                    if (!valid2) break;
                  }
                } else {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.items',
                    schemaPath: '#/properties/items/type',
                    params: {
                      type: 'array'
                    },
                    message: 'should be array'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
            }
          }
        }
      } else {
        validate.errors = [{
          keyword: 'type',
          dataPath: (dataPath || '') + "",
          schemaPath: '#/type',
          params: {
            type: 'object'
          },
          message: 'should be object'
        }];
        return false;
      }
      validate.errors = vErrors;
      return errors === 0;
    };
  })();
  refVal37.schema = {
    "type": "object",
    "required": ["items"],
    "properties": {
      "name": {
        "type": "string"
      },
      "randomize": {
        "type": "boolean"
      },
      "items": {
        "type": "array",
        "items": {
          "$ref": "#/definitions/item"
        }
      }
    }
  };
  refVal37.errors = null;
  refVal[37] = refVal37;
  var refVal38 = {
    "type": "object",
    "properties": {
      "question": {
        "type": "integer"
      },
      "factor": {
        "type": "string"
      },
      "interviewId": {
        "type": "number"
      },
      "round": {
        "type": "number"
      },
      "circuit": {
        "type": "number"
      },
      "room": {
        "type": "number"
      }
    }
  };
  refVal[38] = refVal38;
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict'; /*# sourceURL=https://practique.net/exam.json */
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      if (true) {
        var errs__0 = errors;
        var valid1 = true;
        if (data.exam === undefined) {
          valid1 = false;
          validate.errors = [{
            keyword: 'required',
            dataPath: (dataPath || '') + "",
            schemaPath: '#/required',
            params: {
              missingProperty: 'exam'
            },
            message: 'should have required property \'exam\''
          }];
          return false;
        } else {
          var errs_1 = errors;
          if (!refVal1(data.exam, (dataPath || '') + '.exam', data, 'exam', rootData)) {
            if (vErrors === null) vErrors = refVal1.errors;
            else vErrors = vErrors.concat(refVal1.errors);
            errors = vErrors.length;
          }
          var valid1 = errors === errs_1;
        }
        if (valid1) {
          var data1 = data.candidates;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'candidates'
              },
              message: 'should have required property \'candidates\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (Array.isArray(data1)) {
              var errs__1 = errors;
              var valid1;
              for (var i1 = 0; i1 < data1.length; i1++) {
                var errs_2 = errors;
                if (typeof data1[i1] !== "string") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.candidates[' + i1 + ']',
                    schemaPath: '#/properties/candidates/items/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                var valid2 = errors === errs_2;
                if (!valid2) break;
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.candidates',
                schemaPath: '#/properties/candidates/type',
                params: {
                  type: 'array'
                },
                message: 'should be array'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.candidateDetails;
            if (data1 === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
                var errs__1 = errors;
                var valid2 = true;
                for (var key1 in data1) {
                  var data2 = data1[key1];
                  var errs_2 = errors;
                  var errs_3 = errors;
                  if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                    var errs__3 = errors;
                    var valid4 = true;
                    if (data2.given_name === undefined) {
                      valid4 = true;
                    } else {
                      var errs_4 = errors;
                      if (typeof data2.given_name !== "string") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.candidateDetails[\'' + key1 + '\'].given_name',
                          schemaPath: '#/definitions/userDetails/properties/given_name/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                      var valid4 = errors === errs_4;
                    }
                    if (valid4) {
                      if (data2.family_name === undefined) {
                        valid4 = true;
                      } else {
                        var errs_4 = errors;
                        if (typeof data2.family_name !== "string") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.candidateDetails[\'' + key1 + '\'].family_name',
                            schemaPath: '#/definitions/userDetails/properties/family_name/type',
                            params: {
                              type: 'string'
                            },
                            message: 'should be string'
                          }];
                          return false;
                        }
                        var valid4 = errors === errs_4;
                      }
                      if (valid4) {
                        if (data2.password_hash === undefined) {
                          valid4 = true;
                        } else {
                          var errs_4 = errors;
                          if (typeof data2.password_hash !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.candidateDetails[\'' + key1 + '\'].password_hash',
                              schemaPath: '#/definitions/userDetails/properties/password_hash/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid4 = errors === errs_4;
                        }
                        if (valid4) {
                          var data3 = data2.variantIndex;
                          if (data3 === undefined) {
                            valid4 = true;
                          } else {
                            var errs_4 = errors;
                            if ((typeof data3 !== "number" || (data3 % 1) || data3 !== data3)) {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.candidateDetails[\'' + key1 + '\'].variantIndex',
                                schemaPath: '#/definitions/userDetails/properties/variantIndex/type',
                                params: {
                                  type: 'integer'
                                },
                                message: 'should be integer'
                              }];
                              return false;
                            }
                            var valid4 = errors === errs_4;
                          }
                        }
                      }
                    }
                  } else {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.candidateDetails[\'' + key1 + '\']',
                      schemaPath: '#/definitions/userDetails/type',
                      params: {
                        type: 'object'
                      },
                      message: 'should be object'
                    }];
                    return false;
                  }
                  var valid3 = errors === errs_3;
                  var valid2 = errors === errs_2;
                  if (!valid2) break;
                }
              } else {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.candidateDetails',
                  schemaPath: '#/properties/candidateDetails/type',
                  params: {
                    type: 'object'
                  },
                  message: 'should be object'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              var data1 = data.factors;
              if (data1 === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (Array.isArray(data1)) {
                  var errs__1 = errors;
                  var valid1;
                  for (var i1 = 0; i1 < data1.length; i1++) {
                    var errs_2 = errors;
                    if (typeof data1[i1] !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.factors[' + i1 + ']',
                        schemaPath: '#/properties/factors/items/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid2 = errors === errs_2;
                    if (!valid2) break;
                  }
                } else {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.factors',
                    schemaPath: '#/properties/factors/type',
                    params: {
                      type: 'array'
                    },
                    message: 'should be array'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.factorDetails;
                if (data1 === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
                    var errs__1 = errors;
                    var valid2 = true;
                    for (var key1 in data1) {
                      var data2 = data1[key1];
                      var errs_2 = errors;
                      var errs_3 = errors;
                      if ((data2 && typeof data2 === "object" && !Array.isArray(data2))) {
                        var errs__3 = errors;
                        var valid4 = true;
                        if (data2.given_name === undefined) {
                          valid4 = true;
                        } else {
                          var errs_4 = errors;
                          if (typeof data2.given_name !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.factorDetails[\'' + key1 + '\'].given_name',
                              schemaPath: '#/definitions/userDetails/properties/given_name/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid4 = errors === errs_4;
                        }
                        if (valid4) {
                          if (data2.family_name === undefined) {
                            valid4 = true;
                          } else {
                            var errs_4 = errors;
                            if (typeof data2.family_name !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.factorDetails[\'' + key1 + '\'].family_name',
                                schemaPath: '#/definitions/userDetails/properties/family_name/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid4 = errors === errs_4;
                          }
                          if (valid4) {
                            if (data2.password_hash === undefined) {
                              valid4 = true;
                            } else {
                              var errs_4 = errors;
                              if (typeof data2.password_hash !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.factorDetails[\'' + key1 + '\'].password_hash',
                                  schemaPath: '#/definitions/userDetails/properties/password_hash/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid4 = errors === errs_4;
                            }
                            if (valid4) {
                              var data3 = data2.variantIndex;
                              if (data3 === undefined) {
                                valid4 = true;
                              } else {
                                var errs_4 = errors;
                                if ((typeof data3 !== "number" || (data3 % 1) || data3 !== data3)) {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.factorDetails[\'' + key1 + '\'].variantIndex',
                                    schemaPath: '#/definitions/userDetails/properties/variantIndex/type',
                                    params: {
                                      type: 'integer'
                                    },
                                    message: 'should be integer'
                                  }];
                                  return false;
                                }
                                var valid4 = errors === errs_4;
                              }
                            }
                          }
                        }
                      } else {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.factorDetails[\'' + key1 + '\']',
                          schemaPath: '#/definitions/userDetails/type',
                          params: {
                            type: 'object'
                          },
                          message: 'should be object'
                        }];
                        return false;
                      }
                      var valid3 = errors === errs_3;
                      var valid2 = errors === errs_2;
                      if (!valid2) break;
                    }
                  } else {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.factorDetails',
                      schemaPath: '#/properties/factorDetails/type',
                      params: {
                        type: 'object'
                      },
                      message: 'should be object'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.questions;
                  if (data1 === undefined) {
                    valid1 = false;
                    validate.errors = [{
                      keyword: 'required',
                      dataPath: (dataPath || '') + "",
                      schemaPath: '#/required',
                      params: {
                        missingProperty: 'questions'
                      },
                      message: 'should have required property \'questions\''
                    }];
                    return false;
                  } else {
                    var errs_1 = errors;
                    if (Array.isArray(data1)) {
                      var errs__1 = errors;
                      var valid1;
                      for (var i1 = 0; i1 < data1.length; i1++) {
                        var errs_2 = errors;
                        if (!refVal8(data1[i1], (dataPath || '') + '.questions[' + i1 + ']', data1, i1, rootData)) {
                          if (vErrors === null) vErrors = refVal8.errors;
                          else vErrors = vErrors.concat(refVal8.errors);
                          errors = vErrors.length;
                        }
                        var valid2 = errors === errs_2;
                        if (!valid2) break;
                      }
                    } else {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.questions',
                        schemaPath: '#/properties/questions/type',
                        params: {
                          type: 'array'
                        },
                        message: 'should be array'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.variantDefault === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.variantDefault !== "string") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.variantDefault',
                          schemaPath: '#/properties/variantDefault/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      var data1 = data.variantQuestions;
                      if (data1 === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (Array.isArray(data1)) {
                          var errs__1 = errors;
                          var valid1;
                          for (var i1 = 0; i1 < data1.length; i1++) {
                            var data2 = data1[i1];
                            var errs_2 = errors;
                            if (Array.isArray(data2)) {
                              var errs__2 = errors;
                              var valid2;
                              for (var i2 = 0; i2 < data2.length; i2++) {
                                var errs_3 = errors;
                                if (!refVal[8](data2[i2], (dataPath || '') + '.variantQuestions[' + i1 + '][' + i2 + ']', data2, i2, rootData)) {
                                  if (vErrors === null) vErrors = refVal[8].errors;
                                  else vErrors = vErrors.concat(refVal[8].errors);
                                  errors = vErrors.length;
                                }
                                var valid3 = errors === errs_3;
                                if (!valid3) break;
                              }
                            } else {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.variantQuestions[' + i1 + ']',
                                schemaPath: '#/properties/variantQuestions/items/type',
                                params: {
                                  type: 'array'
                                },
                                message: 'should be array'
                              }];
                              return false;
                            }
                            var valid2 = errors === errs_2;
                            if (!valid2) break;
                          }
                        } else {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.variantQuestions',
                            schemaPath: '#/properties/variantQuestions/type',
                            params: {
                              type: 'array'
                            },
                            message: 'should be array'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        var data1 = data.variantNames;
                        if (data1 === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (Array.isArray(data1)) {
                            var errs__1 = errors;
                            var valid1;
                            for (var i1 = 0; i1 < data1.length; i1++) {
                              var errs_2 = errors;
                              if (typeof data1[i1] !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.variantNames[' + i1 + ']',
                                  schemaPath: '#/properties/variantNames/items/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid2 = errors === errs_2;
                              if (!valid2) break;
                            }
                          } else {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.variantNames',
                              schemaPath: '#/properties/variantNames/type',
                              params: {
                                type: 'array'
                              },
                              message: 'should be array'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          var data1 = data.defaultSections;
                          if (data1 === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (Array.isArray(data1)) {
                              var errs__1 = errors;
                              var valid1;
                              for (var i1 = 0; i1 < data1.length; i1++) {
                                var errs_2 = errors;
                                if (!refVal37(data1[i1], (dataPath || '') + '.defaultSections[' + i1 + ']', data1, i1, rootData)) {
                                  if (vErrors === null) vErrors = refVal37.errors;
                                  else vErrors = vErrors.concat(refVal37.errors);
                                  errors = vErrors.length;
                                }
                                var valid2 = errors === errs_2;
                                if (!valid2) break;
                              }
                            } else {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.defaultSections',
                                schemaPath: '#/properties/defaultSections/type',
                                params: {
                                  type: 'array'
                                },
                                message: 'should be array'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            var data1 = data.candidateSections;
                            if (data1 === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
                                var errs__1 = errors;
                                var valid2 = true;
                                for (var key1 in data1) {
                                  var data2 = data1[key1];
                                  var errs_2 = errors;
                                  if (Array.isArray(data2)) {
                                    var errs__2 = errors;
                                    var valid2;
                                    for (var i2 = 0; i2 < data2.length; i2++) {
                                      var errs_3 = errors;
                                      if (!refVal[37](data2[i2], (dataPath || '') + '.candidateSections[\'' + key1 + '\'][' + i2 + ']', data2, i2, rootData)) {
                                        if (vErrors === null) vErrors = refVal[37].errors;
                                        else vErrors = vErrors.concat(refVal[37].errors);
                                        errors = vErrors.length;
                                      }
                                      var valid3 = errors === errs_3;
                                      if (!valid3) break;
                                    }
                                  } else {
                                    validate.errors = [{
                                      keyword: 'type',
                                      dataPath: (dataPath || '') + '.candidateSections[\'' + key1 + '\']',
                                      schemaPath: '#/properties/candidateSections/additionalProperties/type',
                                      params: {
                                        type: 'array'
                                      },
                                      message: 'should be array'
                                    }];
                                    return false;
                                  }
                                  var valid2 = errors === errs_2;
                                  if (!valid2) break;
                                }
                              } else {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.candidateSections',
                                  schemaPath: '#/properties/candidateSections/type',
                                  params: {
                                    type: 'object'
                                  },
                                  message: 'should be object'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "$id": "https://practique.net/exam.json",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "answerLabel": {
      "type": "object",
      "required": ["type", "label", "backend_id"],
      "properties": {
        "type": {
          "type": "string",
          "enum": ["label"]
        },
        "label": {
          "type": "string"
        },
        "backend_id": {
          "type": "number"
        },
        "visible": {
          "$ref": "#/definitions/expr"
        },
        "indent": {
          "type": "number"
        },
        "displayNumber": {
          "type": "string"
        },
        "notes": {
          "type": "string"
        }
      }
    },
    "answerLongtext": {
      "type": "object",
      "required": ["type", "label", "backend_id"],
      "properties": {
        "type": {
          "type": "string",
          "enum": ["longtext", "text"]
        },
        "label": {
          "type": "string"
        },
        "backend_id": {
          "type": "number"
        },
        "minimumLines": {
          "type": "integer"
        },
        "short": {
          "type": "boolean"
        },
        "visible": {
          "$ref": "#/definitions/expr"
        },
        "valid": {
          "$ref": "#/definitions/expr"
        },
        "indent": {
          "type": "number"
        },
        "displayNumber": {
          "type": "string"
        },
        "notes": {
          "type": "string"
        }
      }
    },
    "answerBoolean": {
      "type": "object",
      "required": ["type", "label", "backend_id"],
      "properties": {
        "type": {
          "type": "string",
          "enum": ["boolean"]
        },
        "label": {
          "type": "string"
        },
        "backend_id": {
          "type": "number"
        },
        "visible": {
          "$ref": "#/definitions/expr"
        },
        "valid": {
          "$ref": "#/definitions/expr"
        },
        "indent": {
          "type": "number"
        },
        "displayNumber": {
          "type": "string"
        },
        "notes": {
          "type": "string"
        }
      }
    },
    "answerOptionDetails": {
      "type": "object",
      "properties": {
        "backend_id": {
          "type": "number"
        },
        "description": {
          "type": "string"
        }
      }
    },
    "answerOptionOrder": {
      "type": "array",
      "items": {
        "type": "number"
      }
    },
    "answerDiscrete": {
      "type": "object",
      "required": ["type", "label", "backend_id", "options"],
      "properties": {
        "type": {
          "type": "string",
          "enum": ["dropdown", "SBA"]
        },
        "label": {
          "type": "string"
        },
        "backend_id": {
          "type": "number"
        },
        "options": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "optionDetails": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/answerOptionDetails"
          }
        },
        "visible": {
          "$ref": "#/definitions/expr"
        },
        "valid": {
          "$ref": "#/definitions/expr"
        },
        "indent": {
          "type": "number"
        },
        "candidateToOptionOrder": {
          "type": "object",
          "description": "map candidate-id to option-order",
          "additionalProperties": {
            "$ref": "#/definitions/answerOptionOrder"
          }
        },
        "displayNumber": {
          "type": "string"
        },
        "notes": {
          "type": "string"
        }
      }
    },
    "optionGroups": {
      "type": "array",
      "items": {
        "type": "object",
        "required": ["options"],
        "properties": {
          "options": {
            "type": "array",
            "items": {
              "type": "string"
            }
          }
        }
      }
    },
    "prescribingOptionGroups": {
      "type": "object",
      "required": ["dose", "route", "frequency"],
      "properties": {
        "dose": {
          "$ref": "#/definitions/optionGroups"
        },
        "route": {
          "$ref": "#/definitions/optionGroups"
        },
        "frequency": {
          "$ref": "#/definitions/optionGroups"
        }
      }
    },
    "answerPrescribing": {
      "type": "object",
      "required": ["type", "label", "backend_id", "options"],
      "properties": {
        "type": {
          "type": "string",
          "enum": ["pvsa"]
        },
        "label": {
          "type": "string"
        },
        "backend_id": {
          "type": "number"
        },
        "options": {
          "$ref": "#/definitions/prescribingOptionGroups"
        },
        "visible": {
          "$ref": "#/definitions/expr"
        },
        "indent": {
          "type": "number"
        },
        "displayNumber": {
          "type": "string"
        },
        "notes": {
          "type": "string"
        }
      }
    },
    "answerPrioritisation": {
      "type": "object",
      "required": ["type", "label", "backend_id", "options"],
      "properties": {
        "type": {
          "type": "string",
          "enum": ["cpq"]
        },
        "label": {
          "type": "string"
        },
        "backend_id": {
          "type": "number"
        },
        "options": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "optionDetails": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/answerOptionDetails"
          }
        },
        "visible": {
          "$ref": "#/definitions/expr"
        },
        "indent": {
          "type": "number"
        },
        "displayNumber": {
          "type": "string"
        },
        "notes": {
          "type": "string"
        }
      }
    },
    "answer": {
      "oneOf": [{
        "$ref": "#/definitions/answerLabel"
      }, {
        "$ref": "#/definitions/answerBoolean"
      }, {
        "$ref": "#/definitions/answerLongtext"
      }, {
        "$ref": "#/definitions/answerDiscrete"
      }, {
        "$ref": "#/definitions/answerPrescribing"
      }, {
        "$ref": "#/definitions/answerPrioritisation"
      }]
    },
    "exprRef": {
      "type": "object",
      "required": ["backend_id"],
      "properties": {
        "backend_id": {
          "type": "number"
        }
      }
    },
    "exprVal": {
      "type": "object",
      "required": ["value"],
      "properties": {
        "value": {
          "type": "string"
        }
      }
    },
    "exprObject": {
      "oneOf": [{
        "$ref": "#/definitions/exprRef"
      }, {
        "$ref": "#/definitions/exprVal"
      }]
    },
    "exprEq": {
      "type": "object",
      "required": ["eq"],
      "properties": {
        "eq": {
          "type": "array",
          "minItems": 2,
          "maxItems": 2,
          "items": [{
            "$ref": "#/definitions/exprObject"
          }, {
            "$ref": "#/definitions/exprObject"
          }]
        }
      }
    },
    "exprGt": {
      "type": "object",
      "required": ["gt"],
      "properties": {
        "gt": {
          "type": "array",
          "minItems": 2,
          "maxItems": 2,
          "items": [{
            "$ref": "#/definitions/exprObject"
          }, {
            "$ref": "#/definitions/exprObject"
          }]
        }
      }
    },
    "exprLt": {
      "type": "object",
      "required": ["lt"],
      "properties": {
        "lt": {
          "type": "array",
          "minItems": 2,
          "maxItems": 2,
          "items": [{
            "$ref": "#/definitions/exprObject"
          }, {
            "$ref": "#/definitions/exprObject"
          }]
        }
      }
    },
    "exprGe": {
      "type": "object",
      "required": ["ge"],
      "properties": {
        "ge": {
          "type": "array",
          "minItems": 2,
          "maxItems": 2,
          "items": [{
            "$ref": "#/definitions/exprObject"
          }, {
            "$ref": "#/definitions/exprObject"
          }]
        }
      }
    },
    "exprLe": {
      "type": "object",
      "required": ["le"],
      "properties": {
        "le": {
          "type": "array",
          "minItems": 2,
          "maxItems": 2,
          "items": [{
            "$ref": "#/definitions/exprObject"
          }, {
            "$ref": "#/definitions/exprObject"
          }]
        }
      }
    },
    "exprNot": {
      "type": "object",
      "required": ["not"],
      "properties": {
        "not": {
          "$ref": "#/definitions/expr"
        }
      }
    },
    "exprAnd": {
      "type": "object",
      "required": ["and"],
      "properties": {
        "and": {
          "type": "array",
          "minItems": 2,
          "items": [{
            "$ref": "#/definitions/expr"
          }, {
            "$ref": "#/definitions/expr"
          }]
        }
      }
    },
    "exprOr": {
      "type": "object",
      "required": ["or"],
      "properties": {
        "or": {
          "type": "array",
          "minItems": 2,
          "items": [{
            "$ref": "#/definitions/expr"
          }, {
            "$ref": "#/definitions/expr"
          }]
        }
      }
    },
    "exprXor": {
      "type": "object",
      "required": ["xor"],
      "properties": {
        "xor": {
          "type": "array",
          "minItems": 2,
          "items": [{
            "$ref": "#/definitions/expr"
          }, {
            "$ref": "#/definitions/expr"
          }]
        }
      }
    },
    "expr": {
      "oneOf": [{
        "$ref": "#/definitions/exprEq"
      }, {
        "$ref": "#/definitions/exprGt"
      }, {
        "$ref": "#/definitions/exprLt"
      }, {
        "$ref": "#/definitions/exprGe"
      }, {
        "$ref": "#/definitions/exprLe"
      }, {
        "$ref": "#/definitions/exprNot"
      }, {
        "$ref": "#/definitions/exprAnd"
      }, {
        "$ref": "#/definitions/exprOr"
      }, {
        "$ref": "#/definitions/exprXor"
      }]
    },
    "imageSingle": {
      "type": "object",
      "required": ["image"],
      "properties": {
        "image": {
          "type": "string"
        },
        "caption": {
          "type": "string"
        }
      }
    },
    "imageSet": {
      "type": "object",
      "required": ["set"],
      "properties": {
        "set": {
          "type": "array",
          "items": {
            "type": "string"
          }
        },
        "caption": {
          "type": "string"
        }
      }
    },
    "resource": {
      "type": "object",
      "required": ["file", "type"],
      "properties": {
        "file": {
          "type": "string"
        },
        "type": {
          "type": "string",
          "enum": ["video", "audio", "pdf"]
        },
        "caption": {
          "type": "string"
        },
        "mime": {
          "type": ["string", "null"]
        }
      }
    },
    "image": {
      "oneOf": [{
        "$ref": "#/definitions/imageSingle"
      }, {
        "$ref": "#/definitions/imageSet"
      }, {
        "$ref": "#/definitions/resource"
      }]
    },
    "question": {
      "type": "object",
      "required": ["title", "stem", "backend_id", "images", "answers"],
      "properties": {
        "title": {
          "type": "string"
        },
        "stem": {
          "type": "string"
        },
        "backend_id": {
          "type": "number"
        },
        "images": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/image"
          }
        },
        "answers": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/answer"
          }
        },
        "answersResources": {
          "type": "array",
          "items": {
            "type": "array",
            "items": {
              "$ref": "#/definitions/image"
            }
          }
        }
      }
    },
    "examMeta": {
      "type": "object",
      "required": ["answer_aes_key"],
      "properties": {
        "answer_aes_key": {
          "type": "string"
        },
        "randomize": {
          "type": "boolean"
        },
        "demo": {
          "type": "boolean"
        },
        "show_question_title": {
          "type": "boolean"
        },
        "enableCopyPaste": {
          "type": "boolean"
        },
        "disableResourceLocking": {
          "type": "boolean"
        },
        "disableCalculator": {
          "type": "boolean"
        },
        "disableAnswerElimination": {
          "type": "boolean"
        },
        "disableDicomGrid": {
          "type": "boolean"
        },
        "hideEmptyItems": {
          "type": "boolean"
        },
        "timing": {
          "$ref": "#/definitions/timing"
        }
      }
    },
    "timing": {
      "type": "object",
      "required": ["counter", "duration", "schedule"],
      "properties": {
        "counter": {
          "type": "string",
          "enum": ["up", "down", "hide"]
        },
        "schedule": {
          "$ref": "#/definitions/schedule"
        }
      }
    },
    "item": {
      "type": "object",
      "properties": {
        "question": {
          "type": "integer"
        },
        "factor": {
          "type": "string"
        },
        "interviewId": {
          "type": "number"
        },
        "round": {
          "type": "number"
        },
        "circuit": {
          "type": "number"
        },
        "room": {
          "type": "number"
        }
      }
    },
    "section": {
      "type": "object",
      "required": ["items"],
      "properties": {
        "name": {
          "type": "string"
        },
        "randomize": {
          "type": "boolean"
        },
        "items": {
          "type": "array",
          "items": {
            "$ref": "#/definitions/item"
          }
        }
      }
    },
    "userDetails": {
      "type": "object",
      "properties": {
        "given_name": {
          "type": "string"
        },
        "family_name": {
          "type": "string"
        },
        "password_hash": {
          "type": "string"
        },
        "variantIndex": {
          "type": "integer"
        }
      }
    },
    "scheduleMessage": {
      "type": "object",
      "required": ["time", "type"],
      "properties": {
        "time": {
          "type": "number"
        },
        "duration": {
          "type": "number"
        },
        "type": {
          "type": "string",
          "enum": ["message"]
        },
        "value": {
          "type": "string"
        }
      }
    },
    "scheduleReadOnly": {
      "type": "object",
      "required": ["time", "type"],
      "properties": {
        "time": {
          "type": "number"
        },
        "duration": {
          "type": "number"
        },
        "type": {
          "type": "string",
          "enum": ["read-only"]
        }
      }
    },
    "scheduleReadWrite": {
      "type": "object",
      "required": ["time", "type"],
      "properties": {
        "time": {
          "type": "number"
        },
        "duration": {
          "type": "number"
        },
        "type": {
          "type": "string",
          "enum": ["read-write"]
        }
      }
    },
    "schedule": {
      "type": "array",
      "items": {
        "oneOf": [{
          "$ref": "#/definitions/scheduleMessage"
        }, {
          "$ref": "#/definitions/scheduleReadOnly"
        }, {
          "$ref": "#/definitions/scheduleReadWrite"
        }]
      }
    }
  },
  "type": "object",
  "required": ["exam", "candidates", "questions"],
  "properties": {
    "exam": {
      "$ref": "#/definitions/examMeta"
    },
    "candidates": {
      "type": "array",
      "description": "array of candidate-ids",
      "items": {
        "type": "string"
      }
    },
    "candidateDetails": {
      "type": "object",
      "description": "map candidate-id to user details",
      "additionalProperties": {
        "$ref": "#/definitions/userDetails"
      }
    },
    "factors": {
      "type": "array",
      "description": "array of factor names",
      "items": {
        "type": "string"
      }
    },
    "factorDetails": {
      "type": "object",
      "description": "map factor-id to user details",
      "additionalProperties": {
        "$ref": "#/definitions/userDetails"
      }
    },
    "questions": {
      "type": "array",
      "description": "array of all (default variant) questions",
      "items": {
        "$ref": "#/definitions/question"
      }
    },
    "variantDefault": {
      "description": "name of the default variant",
      "type": "string"
    },
    "variantQuestions": {
      "type": "array",
      "description": "array of variant question arrays",
      "items": {
        "type": "array",
        "items": {
          "$ref": "#/definitions/question"
        }
      }
    },
    "variantNames": {
      "type": "array",
      "description": "array of variant names",
      "items": {
        "type": "string"
      }
    },
    "defaultSections": {
      "type": "array",
      "description": "default section order to use if candidateSections not provided for the candidate-id",
      "items": {
        "$ref": "#/definitions/section"
      }
    },
    "candidateSections": {
      "type": "object",
      "description": "map candidate-id to array of sections",
      "additionalProperties": {
        "type": "array",
        "items": {
          "$ref": "#/definitions/section"
        }
      }
    }
  }
};
validate.errors = null;
module.exports = validate;