import { mkNode } from './utils';
import { Img } from './image-base';
import { Thumbnail } from './thumbnail-base';

export class ResourceAudio implements Thumbnail {
    public readonly figure: HTMLDivElement;
    private figcaption: HTMLDivElement;
    private audio: HTMLAudioElement;
    private figinfo: HTMLDivElement;
    //private cols: number;
    private rows: number;

    public image: Img;

    public constructor(image: Img, frag: Node) {
        this.image = image;
        //this.cols = (image.cols != undefined && image.rows != undefined) ? Math.floor(100 * image.cols / image.rows) : 100;
        this.rows = 120;
        
        this.figure = mkNode('div', {
            className: 'figure config-background-highlight config-background-highlight-hover config-primary-border-empty-focus  config-primary-shadow-empty-focus',
            attrib: {tabindex: '0'}
        });
        this.figcaption = mkNode('div', {
            className: 'figcaption', 
            parent: this.figure
        });       
        this.audio = mkNode('audio', {
            className: 'thumbnail',
            parent: this.figure,
            style: {visibility: 'hidden', height: this.rows + 'px'},
            attrib: {controls: 'true'}
        });
        this.figinfo = mkNode('div', {
            className: 'figinfo', 
            parent: this.figure
        });
        this.figcaption.innerHTML = image.caption || '&nbsp;'
        this.figinfo.innerHTML = (image.frames > 1) ? ('[' + image.frames + ' slices]') : '&nbsp;';
        frag.appendChild(this.figure);
    }

    public destroy(): void {
        if (this.audio.src) {
            console.log('REVOKE AUDIO URL');
            URL.revokeObjectURL(this.audio.src);
            this.audio.src = '';
        }
    }

    public async load(buffers: ArrayBuffer|null): Promise<void> {
        const mime = this.image.mime;
        if (buffers) { // } && mime) {
            const blob = new Blob([buffers], {type: mime});
            this.audio.src = URL.createObjectURL(blob);
        } else {
            console.error('video steam null');
        }
        this.audio.style.visibility = 'visible';
    }

    public select(x: boolean): void {
        this.figure.className = x ? 'figure-open' : 'figure';
    }

    public disable(disable: boolean): void {
        this.figure.setAttribute('aria-disabled', String(disable));
        this.figure.setAttribute('tabindex', disable ? '-1' : '0');
    }

    public isDisabled(): boolean {
        return this.figure.getAttribute('aria-disabled') === 'true';
    }

    public contains(node: Node): boolean {
        return this.figure.contains(node);
    }

    public async open(): Promise<void> {
        return;
    } 
}
