import { faUserPlus, faUserMinus, faVideo, faMicrophone, faMicrophoneAlt, faMicrophoneAltSlash, faVolumeOff } from '@fortawesome/free-solid-svg-icons';
import { mkNode, removeChildren, replaceIcon, getPixelScale, removeNode } from './utils';
import { urlWithCredentials, httpDelete, getJson } from './utils-net';
import { ControlPanel } from './question-base';
import { ConsoleLogger, DefaultDeviceController, DefaultMeetingSession, LogLevel, MeetingSessionConfiguration,
    MeetingSession, AudioVideoObserver, VideoTileState, MeetingSessionStatus, DeviceChangeObserver, EventName, EventAttributes, MeetingSessionStatusCode, AudioProfile,
} from 'amazon-chime-sdk-js';
import { dbGet, dbPut } from 'utils-db';
import { translate } from 'utils-lang';

function calcTileMaxSize(): number {
    return Math.floor(360.0 / getPixelScale().y);
}


interface Appointment {
    Meeting: unknown;
    Attendee: unknown;
    elapsedTime?: number;
    details?: {[id:string]:{role: string}};        
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAppointment(x: any): x is Appointment {
    return x && typeof x === 'object' &&
        'Meeting' in x &&
        'Attendee' in x &&
        (typeof x.elapsedTime === 'undefined' || typeof x.elapsedTime === 'number') &&
        (typeof x.details === 'undefined' || typeof x.details === 'object'); 
}

export enum MeetingEvent {
    Connected,
    Message,
}

export interface MeetingEventObserver {
    handleMeetingEvent(type: MeetingEvent.Connected, count: number): Promise<void>;
    handleMeetingEvent(type: MeetingEvent.Message, html?: string): Promise<void>;
}

export class MeetingViewer implements AudioVideoObserver, DeviceChangeObserver {
    private controlPanel: ControlPanel;
    private meetingPanel: HTMLElement;
    private deviceBar: HTMLElement;
    private meetingBar: HTMLElement;
    private connectButton: HTMLButtonElement;
    private videoInButton?: HTMLButtonElement;
    private audioInButton?: HTMLButtonElement;
    private audioOutButton?: HTMLButtonElement;
    private muteButton?: HTMLButtonElement;
    private muteText?: HTMLElement;
    private muteIcon?: HTMLElement;
    private connectText: Text;
    private connectIconSpan: HTMLSpanElement;
    private audio: HTMLAudioElement;
    private callTimer?: HTMLDivElement;
    private interval?: number;

    private video: Map<number, {container: HTMLDivElement, element: HTMLVideoElement, text: HTMLDivElement}> = new Map();
    private connected = false;
    private meetingSession?: MeetingSession;
    private examId: string;
    private candidateId: string;
    private interviewId: number;
    private setNavigating: (s: boolean) => void;
    private audioInputDevices: MediaDeviceInfo[] = [];
    private audioOutputDevices: MediaDeviceInfo[] = [];
    private videoInputDevices: MediaDeviceInfo[] = [];
    private selectedVideoInput = '';
    private selectedAudioInput = '';
    private selectedAudioOutput = '';
    private videoInOpen = false;
    private audioInOpen = false;
    private audioOutOpen = false;
    private attendeePresenceSet = new Set();
    private eventObserver: MeetingEventObserver;
    private startTime = new Date().getTime() / 1000;
    private attendeeDetails: {[id:string]:{role:string}} = {};
    private pixelScale: {x: number, y: number};

    public getPresent(): number {
        return this.attendeePresenceSet.size;
    }

    private setConnected(): void {
        this.connectText.textContent = 'DISCONNECT';
        replaceIcon(this.connectIconSpan, faUserMinus);
        this.connected = true;
        this.connectButton.disabled = false;
    }

    private setDisconnected(): void {
        this.connectText.textContent = 'CONNECT';
        replaceIcon(this.connectIconSpan, faUserPlus);
        this.connected = false;
        this.connectButton.disabled = false;
    }

    private alohaVideoInButton(show: boolean): void {
        if ((!show || this.videoInputDevices.length < 2) && this.videoInButton) {
            this.controlPanel.remove(this.videoInButton);
            this.videoInButton.removeEventListener('click', this.handleVideoIn);
            this.videoInButton = undefined;
        } else if (show && !this.videoInButton && this.videoInputDevices.length > 1) {
            this.videoInButton = mkNode('button', {
                className: 'app-button config-primary-hover',
                children: [
                    mkNode('icon', {icon: faVideo}),
                    mkNode('span', {
                        className: 'app-button-text', children: [
                            mkNode('text', {text: 'CAMERA'})
                        ]
                    })
                ]
            });
            if (this.videoInButton) {
                this.controlPanel.add(this.videoInButton);
                this.videoInButton.addEventListener('click', this.handleVideoIn);
            }
        }
    }

    private alohaAudioInButton(show: boolean): void {
        if ((!show || this.audioInputDevices.length < 2) && this.audioInButton) {
            this.controlPanel.remove(this.audioInButton);
            this.audioInButton.removeEventListener('click', this.handleAudioIn);
            this.audioInButton = undefined;
        } else if (show && !this.audioInButton && this.audioInputDevices.length > 1) {
            this.audioInButton = mkNode('button', {
                className: 'app-button config-primary-hover',
                children: [
                    mkNode('icon', {icon: faMicrophone}),
                    mkNode('span', {
                        className: 'app-button-text', children: [
                            mkNode('text', {text: 'MICROPHONE'})
                        ]
                    })
                ]
            });
            if (this.audioInButton) {
                this.controlPanel.add(this.audioInButton);
                this.audioInButton.addEventListener('click', this.handleAudioIn);
            }
        }
    }

    private alohaAudioOutButton(show: boolean): void {
        if ((!show || this.audioOutputDevices.length < 2) && this.audioOutButton) {
            this.controlPanel.remove(this.audioOutButton);
            this.audioOutButton.removeEventListener('click', this.handleAudioOut);
            this.audioOutButton = undefined;
        } else if (show && !this.audioOutButton && this.audioOutputDevices.length > 1) {
            this.audioOutButton = mkNode('button', {
                className: 'app-button config-primary-hover',
                children: [
                    mkNode('icon', {icon: faVolumeOff}),
                    mkNode('span', {
                        className: 'app-button-text', children: [
                            mkNode('text', {text: 'SPEAKERS'})
                        ]
                    })
                ]
            });
            if (this.audioOutButton) {
                this.controlPanel.add(this.audioOutButton);
                this.audioOutButton.addEventListener('click', this.handleAudioOut);
            }
        }
    }

    private alohaMuteButton(show: boolean): void {
        if (!show && this.muteButton) {
            this.controlPanel.remove(this.muteButton);
            this.muteButton.removeEventListener('click', this.handleMute);
            this.muteButton = undefined;
        } else if (show && !this.muteButton) {
            this.muteText = mkNode('span', {
                className: 'app-button-text', children: [
                    mkNode('text', {text: 'MUTE'})
                ]
            });
            this.muteIcon = mkNode('icon', {icon: faMicrophoneAlt});
            this.muteButton = mkNode('button', {
                className: 'app-button config-primary-hover',
                children: [
                    this.muteIcon,
                    this.muteText,
                ]
            });
            if (this.muteButton) {
                this.controlPanel.add(this.muteButton);
                this.muteButton.addEventListener('click', this.handleMute);
            }
        }
    }

    private readonly handleMute = (): void => {
        if (this.meetingSession) {
            if (this.meetingSession.audioVideo.realtimeIsLocalAudioMuted()) {
                const unmuted = this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
                if (unmuted && this.muteButton && this.muteText && this.muteIcon) {
                    this.muteText.textContent = 'MUTE';
                    const icon = mkNode('icon', {icon: faMicrophoneAlt});
                    this.muteButton.replaceChild(icon, this.muteIcon);
                    this.muteIcon = icon;
                }
            } else {
                this.meetingSession.audioVideo.realtimeMuteLocalAudio();
                if (this.muteButton && this.muteText && this.muteIcon) {
                    this.muteText.textContent = 'UNMUTE';
                    const icon = mkNode('icon', {icon: faMicrophoneAltSlash});
                    this.muteButton.replaceChild(icon, this.muteIcon);
                    this.muteIcon = icon;
                }
            }
        }
    }

    public constructor(
        setNavigating: (a: boolean) => void,
        controlPanel: ControlPanel,
        meetingBar: HTMLElement, 
        examId: string,
        candidateId: string,
        eventObserver: MeetingEventObserver,
        interviewId: number
    ) {
        this.eventObserver = eventObserver;
        this.controlPanel = controlPanel;
        this.meetingPanel = meetingBar;
        this.deviceBar = mkNode('div', {className: 'sub-control', parent: this.meetingPanel});
        this.meetingBar = mkNode('div', {className: 'meeting-bar', parent: this.meetingPanel});
        this.audio = mkNode('audio', {
            parent: this.meetingBar
        });
        this.connectIconSpan = mkNode('span', {children: [
            mkNode('icon', {icon: faUserPlus})
        ]});
        this.connectText = mkNode('text', {text: 'CONNECT'})
        this.connectButton = mkNode('button', {
            className: 'app-button config-primary-hover',
            attrib: {disabled: 'true'},
            children: [
                this.connectIconSpan,
                mkNode('span', {
                    className: 'app-button-text', children: [
                        this.connectText
                    ]
                })
            ]
        });
        this.controlPanel.add(this.connectButton);
        this.examId = examId;
        this.candidateId = candidateId;
        this.interviewId = interviewId;
        this.pixelScale = getPixelScale();
        this.setNavigating = setNavigating;
        window.addEventListener('resize', this.windowResizeHandler);
    }

    private async bestAudioInput(meetingSession: MeetingSession, mediaDevices: MediaDeviceInfo[]): Promise<void> {
        if (mediaDevices.length > 0) {
            const savedPick = await dbGet('session', 'audio-in');
            let pick = null;
            if (typeof savedPick === 'string') {
                pick = mediaDevices.find(d => d.deviceId === savedPick)?.deviceId;
            }
            if (!pick) {
                pick = mediaDevices.find(d => d.deviceId === 'communications')?.deviceId
                    || mediaDevices.find(d => d.deviceId === 'default')?.deviceId
                    || mediaDevices[0].deviceId;
            }  
            await meetingSession.audioVideo.chooseAudioInputDevice(pick);
            this.selectedAudioInput = pick;
            this.audioInputDevices = mediaDevices.filter(ai => ai.deviceId !== 'default' && ai.deviceId !== 'communications');
        } else {
            this.audioInputDevices = [];
        }
    }

    private async bestAudioOutput(meetingSession: MeetingSession, mediaDevices: MediaDeviceInfo[]) {
        if (mediaDevices.length > 0) {
            const savedPick = await dbGet('session', 'audio-out');
            let pick = null;
            if (typeof savedPick === 'string') {
                pick = mediaDevices.find(d => d.deviceId === savedPick)?.deviceId;
            }
            if (!pick) {
                pick = mediaDevices.find(d => d.deviceId === 'communications')?.deviceId
                    || mediaDevices.find(d => d.deviceId === 'default')?.deviceId
                    || mediaDevices[0].deviceId;
            }
            await meetingSession.audioVideo.chooseAudioOutputDevice(pick);
            this.selectedAudioOutput = pick;
            this.audioOutputDevices = mediaDevices.filter(ai => ai.deviceId !== 'default' && ai.deviceId !== 'communications');
        } else {
            this.audioOutputDevices = [];
        }
    }

    private async bestVideoInput(meetingSession: MeetingSession, mediaDevices: MediaDeviceInfo[]) {
        if (mediaDevices.length > 0) {
            const savedPick = await dbGet('session', 'video-in');
            let pick = null;
            if (typeof savedPick === 'string') {
                pick = mediaDevices.find(d => d.deviceId === savedPick)?.deviceId;
            }
            if (!pick) {
                pick = mediaDevices.find(d => /front/.test(d.label.toLocaleLowerCase()))?.deviceId
                    || mediaDevices[0].deviceId;
            }
            await meetingSession.audioVideo.chooseVideoInputDevice(pick);
            this.selectedVideoInput = pick;
            this.videoInputDevices = mediaDevices.filter(ai => ai.deviceId !== 'default' && ai.deviceId !== 'communications');
        } else {
            this.videoInputDevices = [];
        }
    }

    private async startMeeting(): Promise<void> {
        try {  
            this.setNavigating(true);
            this.connectButton.disabled = true;
            this.connectText.textContent = 'CONNECTING';
            const appointment = await getJson(urlWithCredentials(
                '/app/' + this.examId + 
                '/appointment/' + this.interviewId + '/' + this.candidateId + '/'
            ));
            if (!isAppointment(appointment)) {
                throw new TypeError('Server returned invalid Appointment.');
            }
            this.startTime = new Date().getTime() / 1000.0 - (appointment.elapsedTime ?? 0);
            this.attendeeDetails = appointment.details ?? {};
            console.log('APPOINTMENT:', appointment);
            
            const logger = new ConsoleLogger('MeetingLogger', LogLevel.WARN);
            const deviceController = new DefaultDeviceController(logger);

            // No point in getting higher resolution with current tile size.
            // deviceController.chooseVideoInputQuality(640, 360, 30, 800);
            const configuration = new MeetingSessionConfiguration(appointment.Meeting, appointment.Attendee);
            this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
            this.meetingSession.audioVideo.setAudioProfile(AudioProfile.fullbandSpeechMono());
            this.meetingSession.audioVideo.addDeviceChangeObserver(this);

            await this.bestAudioInput(this.meetingSession, await this.meetingSession.audioVideo.listAudioInputDevices());
            await this.bestAudioOutput(this.meetingSession, await this.meetingSession.audioVideo.listAudioOutputDevices());
            
            try {
                await this.meetingSession.audioVideo.bindAudioElement(this.audio);
            } catch (err) {
                console.error('BIND_AUDIO', err);
            }

            await this.bestVideoInput(this.meetingSession, await this.meetingSession.audioVideo.listVideoInputDevices());

            this.alohaMuteButton(true);
            this.alohaAudioInButton(true);
            this.alohaAudioOutButton(true);
            this.alohaVideoInButton(true);
            
            this.meetingSession.audioVideo.addObserver(this);
            this.meetingSession.audioVideo.start();
            this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.handlePresence);
            // signal strength
            this.meetingSession.audioVideo.startLocalVideoTile();
        } catch (err) {
            this.setNavigating(false);
            this.setDisconnected();
            throw err;
        }
    }

    public connectionDidBecomeGood(): void {
        this.eventObserver.handleMeetingEvent(MeetingEvent.Message);
    }

    public connectionDidBecomePoor(): void {
        this.eventObserver.handleMeetingEvent(MeetingEvent.Message, translate('MEETING_POOR_CONNECTION'));
    }

    public connectionDidSuggestStopVideo(): void {
        this.eventObserver.handleMeetingEvent(MeetingEvent.Message, translate('MEETING_POOR_CONNECTION'));
    }

    /*
    public videoNotReceivingEnoughData(): void {
        this.eventObserver.handleMeetingEvent(MeetingEvent.Message, translate('MEETING_POOR_DOWN_CONNECTION'));
    }

    public metricsDidReceive(clientMetricReport: ClientMetricReport): void {
        console.warn('METRICS', clientMetricReport);
    }
    */

    /* FOR LIMIT OF 16 VIDEO STREAMS
    public readonly videoSendDidBecomeUnavailable = ():void => {
        this.eventObserver.handleMeetingEvent(MeetingEvent.Message, translate('MEETING_NO_VIDEO'));
    }

    public readonly videoAvailabilityDidChange = (videoAvailability: MeetingSessionVideoAvailability): void => {
        if (videoAvailability.canStartLocalVideo) {
            this.eventObserver.handleMeetingEvent(MeetingEvent.Message);
        } else {
            this.eventObserver.handleMeetingEvent(MeetingEvent.Message, translate('MEETING_NO_VIDEO'));
        }
    }
    */

    private readonly handlePresence = async (presentAttendeeId: string, present: boolean): Promise<void> => {
        const oldSize = this.attendeePresenceSet.size;
        if (present) {
            this.attendeePresenceSet.add(presentAttendeeId);
        } else {
            this.attendeePresenceSet.delete(presentAttendeeId);
        }
        const newSize = this.attendeePresenceSet.size;
        if (newSize !== oldSize) {
            await this.eventObserver.handleMeetingEvent(MeetingEvent.Connected, newSize);
        }
    };

    public async audioInputsChanged(freshAudioInputDeviceList: MediaDeviceInfo[]): Promise<void> {
        removeChildren(this.deviceBar);
        if (this.meetingSession) {
            await this.bestAudioInput(this.meetingSession, freshAudioInputDeviceList);  
        }
        this.alohaAudioInButton(true);
        if (this.audioInOpen) {
            this.showDeviceList('Choose which microphone to use:', this.audioInputDevices, this.selectedAudioInput, this.chooseAudioInputDevice);    
        }
    }

    public async audioOutputsChanged(freshAudioOutputDeviceList: MediaDeviceInfo[]): Promise<void> {
        removeChildren(this.deviceBar);
        if (this.meetingSession) {
            await this.bestAudioOutput(this.meetingSession, freshAudioOutputDeviceList);
        }
        this.alohaAudioOutButton(true);
        if (this.audioOutOpen) {
            this.showDeviceList('Choose which speakers/headset to use:', this.audioOutputDevices, this.selectedAudioOutput, this.chooseAudioOutputDevice);
        }
    }

    public async videoInputsChanged(freshVideoInputDeviceList: MediaDeviceInfo[]): Promise<void> {
        removeChildren(this.deviceBar);
        if (this.meetingSession) {
            await this.bestVideoInput(this.meetingSession, freshVideoInputDeviceList);
        } 
        this.alohaVideoInButton(true);
        if (this.videoInOpen) {
            this.showDeviceList('Choose which camera to use:', this.videoInputDevices, this.selectedVideoInput, this.chooseVideoInputDevice);
        }
    }

    public audioVideoDidStart(): void {
        console.log('AUDIO_VIDEO_DID_START');
        //if (this.meetingSession) {
        //    this.meetingSession.audioVideo.startLocalVideoTile();
        //}
        this.setConnected();
        this.setNavigating(false);
    }

    public audioVideoDidStartConnecting(reconnecting: boolean): void {
        if (reconnecting) {
              this.connectText.textContent = 'RECONNECTING';
        }
    }

    public async audioVideoDidStop(sessionStatus: MeetingSessionStatus): Promise<void> {
        const statusCode = sessionStatus.statusCode();
        console.warn('AUDIO_VIDEO_DID_STOP', MeetingSessionStatusCode[statusCode]);
        console.warn('FAILURE', sessionStatus.isFailure());
        console.warn('TERMINAL', sessionStatus.isTerminal());
        console.warn('AUDIO_CONN_FAIL', sessionStatus.isAudioConnectionFailure());
        switch (statusCode) {
            case MeetingSessionStatusCode.Left:
            case MeetingSessionStatusCode.MeetingEnded:
                break;
            default:
                await this.eventObserver.handleMeetingEvent(
                    MeetingEvent.Message, 
                    translate('MEETING_STATUS_ERROR', {err: MeetingSessionStatusCode[statusCode]})
                );
                break;
        }
        if (this.meetingSession) {
            this.meetingSession.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(this.handlePresence);
            this.meetingSession.audioVideo.removeObserver(this);
            this.meetingSession.audioVideo.removeDeviceChangeObserver(this);
        }
        removeChildren(this.deviceBar);
        //this.alohaTimer(false);
        this.alohaAudioInButton(false);
        this.alohaAudioOutButton(false);
        this.alohaVideoInButton(false);
        this.alohaMuteButton(false);
        await this.eventObserver.handleMeetingEvent(MeetingEvent.Connected, 0);
        this.setDisconnected();
        this.setNavigating(false);
        this.attendeePresenceSet.clear();
        try {
            await httpDelete(urlWithCredentials(
                '/app/' + this.examId + 
                '/appointment/' + this.interviewId + '/' + this.candidateId + '/'
            ));
        } catch (err) {
            console.error('DELETING INTERVIEW: ', err);
        }
    }

    //private tileCounter = 0;

    public videoTileDidUpdate(tileState: VideoTileState): void {
        console.log('VIDEO_TILE_DID_UPDATE', tileState);
        if (tileState.tileId != null && this.meetingSession && tileState.boundAttendeeId /*&& !tileState.localTile*/ && !tileState.isContent) {
            let videoTile = this.video.get(tileState.tileId);
            if (!videoTile) {
                const container = mkNode('div', {className: 'video-tile' /*, attrib: {order: (this.tileCounter++).toString()}*/});
                const element = mkNode('video', {
                    parent: container,
                    className: 'video-element',
                    style: {visibility: 'visible'},
                    attrib: {show: 'true', playsinline: 'true'}
                });
                const text = mkNode('div', {className: 'video-caption', parent: container});
                videoTile = {container, element, text};
                if (this.callTimer) {
                    this.meetingBar.insertBefore(videoTile.container, this.callTimer);
                } else {
                    this.meetingBar.appendChild(videoTile.container);
                }
                this.video.set(tileState.tileId, videoTile);
            } 
            this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoTile.element);
            if (tileState.active) {
                videoTile.element.style.display = 'block';
                videoTile.element.style.maxHeight = calcTileMaxSize() + 'px';
                const userId = tileState.boundExternalUserId;
                if (userId) {
                    videoTile.text.textContent = (this.attendeeDetails[userId]?.role ?? userId).toUpperCase();
                } 
            } else {
                videoTile.element.style.display = 'none';
                videoTile.text.textContent = '';
            }
        }
    }

    public videoTileWasRemoved(tileId: number): void {
        console.log('VIDEO_TILE_WAS_REMOVED', tileId);
        const videoTile = this.video.get(tileId);
        if (videoTile) {
            this.meetingBar.removeChild(videoTile.container);
            this.video.delete(tileId);
        }
    }    

    private readonly handleConnect = async (): Promise<void> => {
        if (!this.connected) {
            try {
                await this.startMeeting();
            } catch (err) {
                console.error('START_MEETING', err);
            }
        } else {
             this.stopMeeting();
        }
    }

    private showDeviceList(help: string, devices: MediaDeviceInfo[], selectedId: string, selectDevice: (device: MediaDeviceInfo) => Promise<void>): void {
        mkNode('div', {parent: this.deviceBar, className: 'device-bar', children: [
            mkNode('text', {text: help}),
        ]});
        const deviceBar = mkNode('div', {parent: this.deviceBar, className: 'meetingBar'});
        for (const dev of devices) {
            //console.log('COMPARE:', dev.deviceId, selectedId);
            const button = mkNode('button', {
                parent: deviceBar,
                className: 'app-button config-primary-hover',
                children: [
                    mkNode('span', {
                        attrib: {style: 'font-weight:' + ((dev.deviceId == selectedId) ? 'bold' : 'normal')},
                        children: [
                            mkNode('text', {text: dev.label})
                        ]
                    })
                ]
            });
            button.addEventListener('click', async () => selectDevice(dev));
        }
    }

    private readonly chooseVideoInputDevice = async (device: MediaDeviceInfo): Promise<void> => {
        if (this.meetingSession) {
            await this.meetingSession.audioVideo.chooseVideoInputDevice(device.deviceId);
            removeChildren(this.deviceBar);
            this.videoInOpen = false;
            this.selectedVideoInput = device.deviceId;
            try {
                await dbPut('session', 'video-in', device.deviceId);
            } catch (err) {
                console.error('CHOOSE_VIDEO_IN', err);
            }
        }
    }

    private readonly handleVideoIn = (): void => {
        removeChildren(this.deviceBar);
        this.audioInOpen = false;
        this.audioOutOpen = false;
        if (!this.videoInOpen) {
            this.videoInOpen = true;
            this.showDeviceList('Choose which camera to use:', this.videoInputDevices, this.selectedVideoInput, this.chooseVideoInputDevice);
        } else {
            this.videoInOpen = false;
        }
    }

    private readonly chooseAudioInputDevice = async (device: MediaDeviceInfo): Promise<void> => {
        if (this.meetingSession) {
            await this.meetingSession.audioVideo.chooseAudioInputDevice(device.deviceId);
            removeChildren(this.deviceBar);
            this.audioInOpen = false;
            this.selectedAudioInput = device.deviceId;
            try {
                await dbPut('session', 'audio-in', device.deviceId)
            } catch (err) {
                console.error('CHOOSE_AUDIO_IN', err);
            }
        }
    }

    private readonly handleAudioIn = (): void => {
        removeChildren(this.deviceBar);
        this.videoInOpen = false;
        this.audioOutOpen = false;
        if (!this.audioInOpen) {
            this.audioInOpen = true;
            this.showDeviceList('Choose which microphone to use:', this.audioInputDevices, this.selectedAudioInput, this.chooseAudioInputDevice);
        } else {
            this.audioInOpen = false;
        }
    }

    private readonly chooseAudioOutputDevice = async (device: MediaDeviceInfo): Promise<void> => {
        if (this.meetingSession) {
            await this.meetingSession.audioVideo.chooseAudioOutputDevice(device.deviceId);
            removeChildren(this.deviceBar);
            this.audioOutOpen = false;
            this.selectedAudioOutput = device.deviceId;
            try {
                await dbPut('session', 'audio-out', device.deviceId);
            } catch (err) {
                console.error('CHOOSE_AUDIO_OUT', err);
            }
        }
    }

    private readonly handleAudioOut = (): void => {
        removeChildren(this.deviceBar);
        this.videoInOpen = false;
        this.audioInOpen = false;
        if (!this.audioOutOpen) {
            this.audioOutOpen = true;
            this.showDeviceList('Choose which speakers/headset to use:', this.audioOutputDevices, this.selectedAudioOutput, this.chooseAudioOutputDevice);
        } else {
            this.audioOutOpen = false;
        }
    }

    public enable(): void {
        this.connectButton.addEventListener('click', this.handleConnect);
        this.connectButton.disabled = false;
    }

    public setInterview(examId: string, candidateId: string, interviewId: number): void {
        this.examId = examId;
        this.candidateId = candidateId;
        this.interviewId = interviewId;
    }

    public readonly windowResizeHandler = (): void => {
        const y = calcTileMaxSize();
        this.video.forEach(videoTile => {
            videoTile.element.style.maxHeight = y + 'px';
        });
    }

    public eventDidReceive(name: EventName, attributes: EventAttributes): void {
        console.warn('EVENT', name);
        switch (name) {
            case 'audioInputFailed':
                alert(`Failed to choose microphone: ${attributes.audioInputErrorMessage}`);
                break;
            case 'videoInputFailed':
                alert(`Failed to choose camera: ${attributes.videoInputErrorMessage}`);
                break;
            case 'meetingStartFailed':
                alert(`Failed to start meeting: ${attributes.meetingErrorMessage}`);
                break;
            case 'meetingFailed':
                alert(`Failed during a meeting: ${attributes.meetingErrorMessage}`);
                break;
            case 'meetingEnded':
                alert(`Meeting Ended: ${attributes.meetingStatus}`);
                break;
            default:
                break;
        }
    }

    public stopMeeting(): void {
        if (this.meetingSession) {
            try {
                this.setNavigating(true);
                this.connectButton.disabled = true;
                this.connectText.textContent = 'DISCONNECTING';
                this.meetingSession.audioVideo.stopLocalVideoTile();
                this.meetingSession.audioVideo.removeLocalVideoTile();
                this.meetingSession.audioVideo.stop();  
            } catch(err) {
                this.setNavigating(false);
                this.setDisconnected();
                throw err;
            }
        }
    }

    public destroy(): void {
        this.stopMeeting();
        window.removeEventListener('resize', this.windowResizeHandler);
        this.connectButton.removeEventListener('click', this.handleConnect);
        this.controlPanel.remove(this.connectButton);
        removeNode(this.deviceBar);
        removeNode(this.meetingBar);
    }
}
