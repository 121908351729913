import { configSolidSafeButton } from 'exam-accessibility';
import { makeElements, NodeSpecObject, removeChildren } from './utils';
import { translate } from './utils-lang';

interface LoginUi {
    loginBox: HTMLDivElement;
    loginLogo: HTMLDivElement;
    loginTextGroup: HTMLDivElement;
    loginCidLabel: HTMLDivElement;
    loginCid: HTMLInputElement;
    loginPinLabel: HTMLDivElement;
    loginPin: HTMLInputElement;
    loginError: HTMLDivElement;
    loginGo: HTMLInputElement;
}

function loginUi(): NodeSpecObject<LoginUi> {
    return {
        loginBox: { elem: 'div', className: 'login-panel config-background' },
        loginLogo: {
            elem: 'div', className: 'logo-panel', parent: 'loginBox', children: [
                { elem: 'img', className: 'client-logo', attrib: { draggable: 'false', src: '/static/images/client-logo.png' } }
            ]
        },
        loginTextGroup: { elem: 'form', className: 'exam-text-group', parent: 'loginBox' },
        loginCidLabel: {
            elem: 'div', className: 'exam-label', parent: 'loginTextGroup',
            children: [
                { elem: 'text', text: translate('LOGIN_CANDIDATE_ID_LABEL') }
            ]
        },
        loginCid: {
            elem: 'input', parent: 'loginCidLabel',
            attrib: { type: 'text', autocorrect: 'off', autocapitalize: 'none', autocomplete: 'off', placeholder: translate('LOGIN_CANDIDATE_ID_HINT') },
        },
        loginPinLabel: {
            elem: 'div', className: 'exam-label', parent: 'loginTextGroup',
            children: [
                { elem: 'text', text: translate('LOGIN_PASSWORD_LABEL') }
            ]
        },
        loginPin: {
            elem: 'input', parent: 'loginPinLabel',
            attrib: { type: 'text', autocorrect: 'off', autocapitalize: 'none', autocomplete: 'off', placeholder: translate('LOGIN_PASSWORD_HINT') },
        },
        loginError: {
            elem: 'div', parent: 'loginBox', className: 'exam-error-text'
        },
        loginGo: {
            elem: 'input', className: configSolidSafeButton, parent: 'loginBox',
            attrib: { type: 'button', value: translate('LOGIN_BUTTON') }
        }
    };
}

export class Auth {
    private parent: HTMLElement;
    private li: LoginUi;

    constructor(parent: HTMLElement) {
        this.li = makeElements(loginUi()) as LoginUi; 
        this.li.loginPin.className = 'navigation-text exam-pin';
        this.li.loginCid.className = 'navigation-text exam-cid';   
        
        parent.appendChild(this.li.loginBox);
        this.parent = parent;
    }

    destroy(): void {
        removeChildren(this.parent);
    }

    private clearError() {
        this.li.loginPin.className = 'navigation-text exam-pin';
        this.li.loginCid.className = 'navigation-text exam-cid';
        this.li.loginError.textContent = '';
    }

    login(args: {admin: boolean; error: string}): Promise<{username: string; password: string}> {
        let error = args.error;
        return new Promise(resolve => {
            if (error) {
                this.li.loginPin.className = 'navigation-text exam-pin exam-error';
                this.li.loginCid.className = 'navigation-text exam-cid exam-error';
                this.li.loginError.textContent = error;
            } else {
                this.clearError();
            }
            this.li.loginPinLabel.style.display = args.admin ? 'none' : 'flex';
            this.li.loginCid.oninput = (): void => {
                if (error) {
                    this.clearError()
                    error = '';
                }
            }
            this.li.loginPin.oninput = (): void => {
                if (error) {
                    this.clearError()
                    error = '';
                }
            }
            this.li.loginGo.onclick = (): void => {
                const user = this.li.loginCid.value.trim();
                const pass = this.li.loginPin.value.trim();
                if (user && (args.admin || pass)) {
                    //this.parent.removeChild(this.li.loginBox);
                    resolve({username: user, password: pass});
                }
            }
        });
    }
}