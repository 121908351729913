import { mkNode } from './utils';
import { Img } from './image-base';
import { Thumbnail } from './thumbnail-base';

export class ResourceVideo implements Thumbnail {
    public readonly figure: HTMLDivElement;
    private figcaption: HTMLDivElement;
    private video: HTMLVideoElement;
    private figinfo: HTMLDivElement;
    //private cols: number;
    private rows: number;

    public image: Img;

    public constructor(image: Img, frag: Node) {
        this.image = image;
        this.rows = 120;
        
        this.figure = mkNode('div', {
            className: 'figure config-background-highlight config-background-highlight-hover config-primary-border-empty-focus config-primary-shadow-empty-focus',
        });
        this.figcaption = mkNode('div', {
            className: 'figcaption', 
            parent: this.figure
        });       
        this.video = mkNode('video', {
            className: 'thumbnail',
            parent: this.figure,
            style: {visibility: 'hidden', height: this.rows + 'px'},
            attrib: {controls: 'true', controlsList: 'nodownload', draggable: 'false'}
        });
        this.figinfo = mkNode('div', {
            className: 'figinfo', 
            parent: this.figure
        });
        this.figcaption.innerHTML = image.caption || '&nbsp;'
        this.figinfo.innerHTML = (image.frames > 1) ? ('[' + image.frames + ' slices]') : '&nbsp;';
        frag.appendChild(this.figure);
    }

    public destroy(): void {
        if (this.video.src) {
            console.log('REVOKE VIDEO URL');
            URL.revokeObjectURL(this.video.src);
            this.video.src = '';
        }
    }

    public async load(buf: ArrayBuffer|null): Promise<void> {
        console.log('IMAGE', this.image);
        const mime = this.image.mime;
        console.log('BUFSIZE', buf?.byteLength);
        if (buf) { // } && mime) {
            const blob = new Blob([buf], {type: mime});
            this.video.src = URL.createObjectURL(blob);
        } else {
            console.error('video steam null');
        }
        this.video.style.visibility = 'visible';
    }

    public select(x: boolean): void {
        this.figure.className = x ? 'figure-open' : 'figure';
    }

    public disable(disable: boolean): void {
        this.figure.setAttribute('aria-disabled', String(disable));
        this.figure.setAttribute('tabindex', disable ? '-1' : '0');
    }

    public isDisabled(): boolean {
        return this.figure.getAttribute('aria-disabled') === 'true';
    }

    public contains(node: Node): boolean {
        return this.figure.contains(node);
    }

    public async open(): Promise<void> {
        return;
    } 
}
