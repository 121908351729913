import { mkNode } from './utils';
import { Thumbnail } from './thumbnail-base';
import { Img } from './image-base';
import { rendererFromImage } from './image-base';
import { DicomViewer } from './image-viewer';


export class ResourceThumbnail implements Thumbnail {
    private figure: HTMLDivElement;
    private figcaption: HTMLDivElement;
    private thumb: HTMLCanvasElement;
    private figinfo: HTMLDivElement;
    private cols: number;
    private rows: number;

    private image: Img;

    public constructor(image: Img, frag: Node) {
        this.image = image;
        this.cols = (image.cols != undefined && image.rows != undefined) ? Math.floor(120 * image.cols / image.rows) : 120;
        this.rows = 120;
        
        this.figure = mkNode('div', {
            className: 'figure config-background-highlight config-background-highlight-hover config-primary-border-empty-focus config-primary-shadow-empty-focus',
            attrib: {tabindex: '0'}
        });
        this.figcaption = mkNode('div', {
            className: 'figcaption', 
            parent: this.figure
        });
        this.thumb = mkNode('canvas', {
            className: 'thumbnail', 
            parent: this.figure,
            style: {visibility: 'hidden', width: this.cols + 'px', height: this.rows + 'px'},
            attrib: {title: 'Click on the thumbnail to view and manipulate the image.', draggable: 'false'},
        });
        this.figinfo = mkNode('div', {
            className: 'figinfo', 
            parent: this.figure
        });
        this.figcaption.innerHTML = image.caption || '&nbsp;'
        this.figinfo.innerHTML = (image.frames > 1) ? ('[' + image.frames + ' slices]') : '&nbsp;';
        frag.appendChild(this.figure);
    }

    public destroy(): void {
        console.log('thumbnail: destroy');
    }

    public async load(thumbnail: ArrayBuffer|null): Promise<void> {
        if (thumbnail) {
            try {
                const cxt = this.thumb.getContext('2d');
                if (cxt != null) {
                    this.thumb.width = this.cols;
                    this.thumb.height = this.rows;
                    if (thumbnail) {
                        const uint8clamped = new Uint8ClampedArray(thumbnail);
                        const imageData = new ImageData(uint8clamped, this.cols, this.rows);
                        cxt.putImageData(imageData, 0, 0);
                    }
                    this.thumb.style.visibility = 'visible';
                }
            } catch (e) {
                console.error('THUMBNAIL LOAD ERROR', e);
            }
        }
    }

    public select(x: boolean): void {
        this.figure.className = x ? 'figure-open' : 'figure';
    }

    public disable(disable: boolean): void {
        this.figure.setAttribute('aria-disabled', String(disable));
        this.figure.setAttribute('tabindex', disable ? '-1' : '0');
    }

    public isDisabled(): boolean {
        return this.figure.getAttribute('aria-disabled') === 'true';
    }

    public contains(node: Node): boolean {
        return this.figure.contains(node);
    }

    public async open(makeViewer: () => DicomViewer): Promise<void> {
        await makeViewer().setDicom(rendererFromImage(this.image));
    } 
}
