import { version } from '@gen/version';
import { makeElements, removeChildren, NodeSpecObject} from 'utils';
import { cleanUpOldExam, runExam, ExamState } from 'exam-viewer';
//import { isTouchDevice, hasMouse } from 'utils-device';
import { dbGet, dbPut, dbDelete } from 'utils-db';
import { setCredentials, setAsCandidate, clearCredentials, HttpError,/*, requestJson*/ 
requestJson} from 'utils-net';
import { Json, StoreChunks, deleteExam, getExamList, State, ExamMap, RemoteExamItem, Structure, deleteUnavailable, getFetchList, fetchExams, getLocalExams, updateLocalExams } from 'exam-service';
import { AssignmentViewer, ChosenExam, Logout } from 'assignment-viewer';
import { Auth } from 'utils-login';
import { Progress } from 'utils-progress';
import { initTranslation, translate, localise, nextLanguage } from 'utils-lang';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

//const shortVersion = version.match(/^p4b-(\S*)/)?.[1];
const debugVersion = version.match(/^\S+\s+DEBUG\s+\[\S+\s+\S+\s+\S+\s+(\d+):(\d+):\d+\s+\S+\s+\S+\]$/);


//import { ExamItem } from './p4b-api';

//import { Proctor } from './exam-proctor';

/*
function singleApplicationModeStartOnSuccess(): void {
    //alert('Single application mode started');
    SAM = true;state
}
 
function singleApplicationModeStartOnError(): void {
    //alert('Single application mode not available');
}
 
function singleApplicationModeExitOnSuccess(): void {
    //alert('Single application mode exited');
    SAM = false;
}
 
function singleApplicationModeExitOnError(): void {
    //alert('Single application mode exit failed');
    
}
*/

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
    interface Window {
        webkit: {
            messageHandlers: {
                iOS: {
                    postMessage: Json;
                };
            };
        };
    }   
}

if (debugVersion) {
    const {debug, info, log, warn, error} = console;
    window.console.debug = (...args: unknown[]): void => {
        debug(JSON.stringify([...args]));
        setImmediate(async () => {
            try {
                await requestJson('/log', ['DEBUG', performance.now() / 1000, ...args]);
            } catch (err) {}
        });
    }
    window.console.info = (...args: unknown[]): void => {
        info(JSON.stringify([...args]));
        setImmediate(async () => {
            try {
                await requestJson('/log', [' INFO', performance.now() / 1000, ...args]);
            } catch (err) {}
        });
    }
    window.console.log = (...args: unknown[]): void => {
        log(JSON.stringify([...args]));
        setImmediate(async () => {
            try {
                await requestJson('/log', ['  LOG', performance.now() / 1000, ...args]);
            } catch (err) {}
        });
    }
    window.console.warn = (...args: unknown[]): void => {
        warn(JSON.stringify([...args]));
        setImmediate(async () => {
            try {
                await requestJson('/log', [' WARN', performance.now() / 1000, ...args]);
            } catch (err) {}
        });
    }
    window.console.error = (...args: unknown[]): void => {
        error(JSON.stringify([...args]));
        setImmediate(async () => {
            try {
                await requestJson('/log', ['ERROR', performance.now() / 1000, ...args]);
            } catch (err) {}
        });
    }
}

function singleApplicationModeStart(): void {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iOS) {
        //window.webkit.messageHandlers.iOS.postMessage({
        //    type: 'sam-start',
        //    succ: "singleApplicationModeStartOnSuccess()",
        //    fail: "singleApplicationModeStartOnError()"
        //});
    }
}

function singleApplicationModeExit(): void {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iOS) {
        //window.webkit.messageHandlers.iOS.postMessage({
        //    type: "sam-exit",
        //    succ: "singleApplicationModeExitOnSuccess()",
        //    fail: "singleApplicationModeExitOnError()"
        //});
    }
}

//----------------------------------------------------------------------------

interface WelcomeUi {
    welcomePage: HTMLDivElement;
    logo: HTMLDivElement;
    version: HTMLDivElement;
    //progress: HTMLSpanElement;
    welcomePanel: HTMLDivElement;
    language: HTMLButtonElement;
}

const welcomeUi: NodeSpecObject<WelcomeUi> = {
    welcomePage: { elem: 'div', className: 'welcome config-primary'},
    version: { elem: 'div', className: 'app-version', parent: 'welcomePage'},
    logo: { elem: 'div', className: 'logo-panel', parent:'welcomePage', children: [
        { elem: 'img', className: 'logo', attrib: { draggable: 'false', src: 'practique-logo-white.png' } }
    ] },
    //progress: { elem: 'span', className: 'progress', parent: 'welcomePage' },
    welcomePanel: { elem: 'div', className: 'choose-exam', parent: 'welcomePage' },
    
    language: {
        elem: 'button', className: 'info-button flat-button-dark', parent: 'welcomePage', children: [
            {elem: 'icon', icon: faLanguage},
        ],
    },
};


//----------------------------------------------------------------------------
// Create UI

const content = document.getElementById('content');

const wi = makeElements(welcomeUi);
//let proctor: Proctor|undefined;
if (content) {
    content.appendChild(wi.welcomePage);
    //proctor = new Proctor(content);
}

wi.language.onclick = async () => {
    await nextLanguage();
    location.reload(false); // reload from cache;
};

//----------------------------------------------------------------------------
// Server API 

const state: State = {
    exams: new Map(),
    structure: [],
    //order: []
};

//----------------------------------------------------------------------------

const imageStore = new StoreChunks(0x1000000, 0x1000000);

interface Credentials {
    username: string;
    password: string;
    candidate: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isCredentials(cred: any): cred is Credentials {
    return cred && 
        typeof cred.username === 'string' && 
        typeof cred.password === 'string' &&
        typeof cred.candidate === 'string';
}

async function login(): Promise<RemoteExamItem[]> {
    //const qs = dbClear('questions');
    //const is = dbClear('images');
    //await dbDelete('users', 'manifest');
    //await qs;
    //await is;

    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Loading candidate credentials...</p>`;
    const savedCredentials = await dbGet('users', 'credentials');
    if (isCredentials(savedCredentials)) {
        const credentials = savedCredentials;
        state.candidateCid = credentials.username;
        state.candidatePin = credentials.password;
        state.examCid = credentials.candidate;
        setCredentials({
            loginId: credentials.username,
            loginPwd: credentials.password,
            asCandidateId: credentials.candidate,
        });
        console.debug('SAVED LOGIN');
        try {
            wi.welcomePanel.innerHTML = `<p class="progress-subtext">Fetching available exams...</p>`;
            return (await getExamList()).exams ?? [];
        } catch (err) {
            console.warn(err.message ?? err);
            return [];
        }
    }

    let loginError = '';
    let examList: RemoteExamItem[] = [];
    removeChildren(wi.welcomePanel);
    const auth = new Auth(wi.welcomePanel);
    while (true) {    
        try {
            const credentials = await auth.login({admin: false, error: loginError});
            state.candidateCid = credentials.username;
            state.candidatePin = credentials.password;   
            loginError = '';
            setCredentials({
                loginId: credentials.username,
                loginPwd: credentials.password,
                asCandidateId: '',
            });
            const {admin, exams} = await getExamList();
            if (admin) {
                const {username} = await auth.login({admin: true, error: ''});
                state.examCid = username;
                setAsCandidate(username);
                examList = (await getExamList()).exams ?? [];
                break;
            } else {
                examList = exams ?? [];
                break;
            }
        } catch (err) {
            if (err instanceof HttpError) {
                if (err.status == 403) {
                    loginError = translate('ERROR_USER_PASS');
                } else {
                    loginError = translate('ERROR_HTTP', {code: localise(err.status)});
                }
            } else {
                loginError = translate('ERROR_UNKNOWN', {message: err.message});
            }
        }
    }
    auth.destroy();
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Saving candidate credentials...</p>`;
    await dbPut('users', 'credentials', {
        username: state.candidateCid,
        password: state.candidatePin,
        candidate: state.examCid ?? '',
    });
    return examList;
}

async function savedExam():  Promise<ChosenExam|null> {
    const savedExam = await dbGet('users', 'exam');
    if (isExamChoice(savedExam)) { 
        console.debug('SAVED');
        if (savedExam.examId && savedExam.pin || (!savedExam.proctored)) {
            console.debug('EXAM & PIN')
            window.history.replaceState(null, '', window.location.pathname);
            return {
                kind: 'exam',
                examId: savedExam.examId,
                examPin: savedExam.pin,
                candidateId: state.candidateCid ?? state.examCid ?? '',
            };
        }
    }
    return null;
}

async function loadExams(remoteExamList: RemoteExamItem[]): Promise<ExamMap|undefined> {
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Loading exam list...</p>`;
    const localExamMap = await getLocalExams();
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Removing finished exams...</p>`;
    await deleteUnavailable(localExamMap, remoteExamList);
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Fetching exam liar...</p>`;
    await updateLocalExams(remoteExamList);
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Fetching new exams...</p>`;
    const fetchList = getFetchList(localExamMap, remoteExamList);    
    if (fetchList.length > 0) {
        removeChildren(wi.welcomePanel);
        const progress = new Progress(wi.welcomePanel); 
        try {
            const downloaded = await fetchExams(fetchList, progress);
            if (downloaded) { 
                return;
            }
        } catch(err) {
            console.error(String(err));
            alert(String(err));
            state.examCid = undefined;
            state.examPin = undefined;
            state.candidateCid = undefined;
            state.candidatePin = undefined;
            clearCredentials();
            await dbDelete('users', 'credentials');
            return;
        } finally {
            progress.destroy();
        }
    }

    return await getLocalExams();
} 

interface ExamChoice {
    examId: string;
    pin: string;
    proctored?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isExamChoice(exam: any): exam is ExamChoice {
    return exam && typeof exam.examId === 'string' && typeof exam.pin === 'string';
}

async function chooseExam(exams: ExamMap, examId: string|null, pin: string|null, proctored: boolean): Promise<ChosenExam|Logout> {
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Loading current exam...</p>`;
    /*const savedExam = await dbGet('users', 'exam');
    if (isExamChoice(savedExam)) { 
        console.debug('SAVED');
        if (savedExam.examId && savedExam.pin || (!savedExam.proctored)) {
            console.debug('EXAM & PIN')
            window.history.replaceState(null, '', window.location.pathname);
            return {
                kind: 'exam',
                examId: savedExam.examId,
                examPin: savedExam.pin,
                candidateId: state.candidateCid ?? state.examCid ?? '',
            };
        }
    } else*/ if (examId && pin) {
        console.debug('PIN', pin, "EXAM", examId);
        window.history.replaceState(null, '', window.location.pathname);
        if (exams.has(examId)) {
            return {
                kind: 'exam',
                examId: examId,
                examPin: pin,
                candidateId: state.candidateCid ?? state.examCid ?? '',
            };
        }
    } else {
        for (const [key, exam] of Array.from(exams.entries())) {
            if (exam.pin && (exam.proctored ? proctored : true)) {
                return {
                    kind: 'exam',
                    examId: key,
                    examPin: exam.pin,
                    candidateId: state.candidateCid ?? state.examCid ?? '',
                }
            }
        }
    }

    removeChildren(wi.welcomePanel);
    const assignmentViewer = new AssignmentViewer({
        parent: wi.welcomePanel,
        badPin: state.badPin === true,
        badCid: state.badCid === true,
        examPin: state.examPin,
        candidateId: state.examCid,
        forceCandidateId: state.candidateCid,
        exams: exams,
        chosenExamId: state.chosenExamId,
    });
    const choice = await assignmentViewer.getExamChoice();
    assignmentViewer.destroy();
    return choice;
}

interface SavedManifest {
    manifest: PractiqueNet.ExamJson;
    structure: Structure[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSavedManifest(savedManifest: any): savedManifest is SavedManifest {
    return savedManifest && typeof savedManifest.manifest === 'object' && typeof savedManifest.structure === 'object';
}

async function prepareExam(exams: ExamMap): Promise<{manifest?: PractiqueNet.ExamJson, structure?: Structure[]}> {
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Loading exam details...</p>`;
    const savedManifest = await dbGet('users', 'manifest');
    if (isSavedManifest(savedManifest)) {
        return savedManifest;
    }

    console.debug('PREPARE EXAM');
    if (state.examPin == null) {
        throw 'EXAM_PIN is null';
    }
    if (state.chosenExamId == null) {
        throw 'CHOSEN_EXAM_ID is null';
    }
    if (state.examCid == null) {
        throw 'CID is null';
    }
    console.debug('GET EXAM', exams, state.chosenExamId);
    const examIndex = exams.get(state.chosenExamId);
    if (examIndex == null) {
        throw 'EXAM_INDEX is null'
    }
    console.debug('GOT EXAM', state.chosenExamId);
    removeChildren(wi.welcomePanel);
    const progress = new Progress(wi.welcomePanel);
    try { 
        const manifestAndStructure =  await imageStore.unpackExam(
            state.examPin,
            state.chosenExamId,
            state.examCid,
            examIndex.size,
            progress
        );
        wi.welcomePanel.innerHTML = `<p class="progress-subtext">Saving current exam...</p>`;
        await dbPut('users', 'exam', {examId: state.chosenExamId, pin: state.examPin, proctored: examIndex.proctored ?? false});
        wi.welcomePanel.innerHTML = `<p class="progress-subtext">Saving exam details...</p>`;
        await dbPut('users', 'manifest', manifestAndStructure);
        return {};
    } finally {
        progress.destroy();
    }
}

// stay in exam until we are sure answers are uploaded
async function exam(exams: ExamMap, meta: PractiqueNet.ExamJson, structure: Structure[]): Promise<void> {
    console.debug('EXAM LAUNCHER', meta.exam.answer_aes_key, state.examCid);
    singleApplicationModeStart();
    if (content && meta.exam.answer_aes_key && state.examCid && state.chosenExamId) {
        console.debug('LAUNCH EXAM');
        meta.exam.demo ||= exams.get(state.chosenExamId)?.demo;
        const context = {
            content: content,
            structure: structure,
            imageStore: imageStore,
            //examId: meta.exam.answer_aes_key,
            //showQuestionTitle: state.thisExam.show_question_title || false,
            //noMouse: isTouchDevice && !hasMouse,
            candidateCid: state.candidateCid,
            examCid: state.examCid,
            //order: state.order,
            //demo: state.thisExam.demo || exams.get(state.chosenExamId)?.demo || false,
            factorDetails: meta.factorDetails,
            meta: meta.exam,
            onDestroy: async () => {
                if (state.candidateCid) {
                    try {
                        await deleteExam(exams, state.chosenExamId, true);
                    } catch (err) {
                        console.warn(err);
                    }
                }
            }
        };
        wi.welcomePanel.innerHTML = `<p class="progress-subtPromise<ChosenExamext">Removing finished exam content...</p>`;
        const examState = await cleanUpOldExam(context);
        if (examState.state !== ExamState.Stopped) {
            removeChildren(wi.welcomePanel);
            await runExam(wi.welcomePanel, examState, context);
        }
    }
    if (!state.candidateCid) {
        state.examCid = undefined;
    }
    state.chosenExamId = undefined;
    state.examPin = undefined;
    singleApplicationModeExit();
    if (content != null) {
        removeChildren(content);
        content.appendChild(wi.welcomePage);
    }
    if (exams.size === 0) { // *TEMPORARY* force full logout if no exams available.
        state.candidateCid = undefined;
        state.candidatePin = undefined;
        wi.welcomePanel.innerHTML = `<p class="progress-subtext">Removing old credentials...</p>`;
        clearCredentials();
        await dbDelete('users', 'credentials');
    }
}


const unsaved = false;

window.onbeforeunload = (e: BeforeUnloadEvent): string|void => {
    if (unsaved) {
        e.preventDefault();
        e.returnValue = 'Logout to ensure all answers have been sent to the server.';
        return 'Logout to ensure all answers have been sent to the server.';
    } else {
        delete e['returnValue'];
    } 
}

/*
interface Proctor {
    proctor: 'examity';
    url: string;
    UserName: string;
}
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/*function isProctor(proctor: any): proctor is Proctor {
    return proctor && 
        (typeof proctor.proctor === 'string') && 
        (typeof proctor.url === 'string') && 
        (typeof proctor.UserName === 'string');
}*/

async function logFreeStorage(): Promise<void> {
    const estimate = await navigator?.storage?.estimate?.();
    if (estimate && estimate.quota && estimate.usage) {
        console.info(`FREE_STORAGE ${Math.floor((estimate.quota - estimate.usage) / 1048576)} MB`);
    }
}

window.onpageshow = async (): Promise<void> => {
    try {
        console.info('P4B VERSION', version);
        logFreeStorage();
        wi.version.innerHTML = version;
        await initTranslation();
        const urlParams = new URLSearchParams(window.location.search);
        console.debug('PARAMS', urlParams.toString());
        const examList = await login();
        const choice = await savedExam();
        if (choice && choice.kind === 'exam') {
            const examMap = await getLocalExams();
            state.chosenExamId = choice.examId;
            state.examPin = choice.examPin;
            state.examCid = choice.candidateId;
            state.badPin = false;
            state.badCid = false;
            try {
                const {manifest, structure} = await prepareExam(examMap);
                if (manifest && structure) {
                    await exam(examMap, manifest, structure);
                }
            } catch (err) {
                if (err === 'probably bad pin') {
                    state.badPin = true;
                //} else if (err === 'candidate not found') {
                //    state.badCid = true;
                } else {
                    console.error(String(err));
                    alert(String(err));
                    state.examCid = undefined;
                    state.examPin = undefined;
                    state.candidateCid = undefined;
                    state.candidatePin = undefined;
                    clearCredentials();
                    await dbDelete('users', 'credentials');
                }
            }
        } else {
            const examMap = await loadExams(examList);
            if (examMap) {
                loop: while (true) {
                    const choice = await chooseExam(examMap, urlParams.get('exam'), urlParams.get('pin'), urlParams.has('proctored'));
                    switch (choice.kind) {
                        case 'exam':
                            state.chosenExamId = choice.examId;
                            state.examPin = choice.examPin;
                            state.examCid = choice.candidateId;
                            state.badPin = false;
                            state.badCid = false;
                            try {
                                const {manifest, structure} = await prepareExam(examMap);
                                if (manifest && structure) {
                                    await exam(examMap, manifest, structure);
                                }
                                break loop;
                            } catch (err) {
                                if (err === 'probably bad pin') {
                                    state.badPin = true;
                                } else if (err === 'candidate not found') {
                                    state.badCid = true;
                                } else {
                                    console.error(String(err));
                                    alert(String(err));
                                    state.examCid = undefined;
                                    state.examPin = undefined;
                                    state.candidateCid = undefined;
                                    state.candidatePin = undefined;
                                    clearCredentials();
                                    await dbDelete('users', 'credentials');
                                    break loop;
                                }
                            }
                            break;
                        case 'logout':
                            state.examCid = undefined;
                            state.examPin = undefined;
                            state.candidateCid = undefined;
                            state.candidatePin = undefined;
                            clearCredentials();
                            await dbDelete('users', 'credentials');
                            break loop;
                    }
                }
            }
        }
    } catch (err) {
        console.error(String(err));
        alert(String(err));
    } finally {
        window.location.reload();
    }
};

