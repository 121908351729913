import { scrollRangeIntoView } from '../utils';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, AnswerResources } from '../question-base';
import { Lightbox } from 'lightbox';

/** Longtext question textarea UI */
export class QuestionLabel extends QuestionBase implements Question {
    //private answerItem: HTMLDivElement;
    //private answerLabel: HTMLDivElement;
    

    public readonly visibilityExpression?: Expr;

    /** Construct LabelQuestion UI */
    public constructor(
        context: QuestionContext,
        qno: number,
        ano: number, 
        backendQid: number,
        backendAid: number,
        showNumber: boolean,
        label: string,
        frag: DocumentFragment,
        lightbox: Lightbox,
        indent?: number,
        visibilityExpression?: Expr,
        resources?: AnswerResources
    ) {
        super(context, frag, qno, ano, backendQid, backendAid, showNumber, false, label, lightbox, undefined, resources);
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`; 
        //this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        //this.answerLabel = mkNode('div', {className: 'answer-label', parent: this.answerItem});
        this.visibilityExpression = visibilityExpression;
        //this.answerLabel.appendChild(this.label);
        //frag.appendChild(this.answerItem);
    }

    public setReadOnly(): void {
        // no-op
    }

    /** Load any stored answer */
    public async loadAnswer(): Promise<void> {
        return Promise.resolve();
    }

    public loadingComplete(): void {
        super.loadingComplete();
    }

    /** Get the answer value */
    public getValue(): string {
        return '';
    }
    /** Set whether this question is visible or hidden */
    public setVisible(vis: boolean): void {
        //this.answerItem.style.display = vis ? 'block' : 'none';
        this.context.setVisible(this.qno, this.ano, vis);
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        //removeChildren(this.answerLabel)
        //removeNode(this.answerItem);
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.label, this.label);
    }
}

registerAnswerType({
    name: 'Label',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'label';
    },
    makeAnswer: (
        pos: number,
        context: QuestionContext,
        _updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.AnswerLabel,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox
    ): Question => {
        return new QuestionLabel(
            context,
            pos, 
            ano,
            question.manifest.backend_id, 
            answer.backend_id, 
            question.manifest.answers.length > 1, 
            answer.label, 
            frag, 
            lightbox,
            answer.indent,
            answer.visible,
            question.answersResources[ano],
        );
    },
});
